const searchQueryGenerator = (schema, data) => {
  const newSchema = [...schema];
  const newData = { ...data };
  const producedQuery = { _and: [] };
  const level = 0;
  const generate = (s, d, parent) => {
    let p = producedQuery._and;
    Object.keys(d).map((key) => {
      if (d[key]) {
        const props = s.filter((sch) => sch.field === key)[0];
        let k = key;

        if (
          props.meta.interface === "select-radio" ||
          props.meta.interface === "select-dropdown" ||
          props.meta.interface === "datetime" ||
          props.meta.interface === "numeric"
        ) {
          p.push({ [k]: { _eq: d[key] } });
        } else if (
          props.meta.interface === "select-multiple-dropdown" ||
          props.meta.interface === "tags"
        ) {
          p.push({
            _or: [
              ...d[key].map((item) => {
                return { [k]: { _contains: item } };
              }),
            ],
          });
          ///next
        } else if (props.meta.interface === "input-code") {
          const test = props.field.split("_");
          if (
            test?.includes("address") &&
            props.meta.options.language === "JSON"
          ) {
            p.push({
              [k]: {
                _eq: JSON.stringify(d[key]),
              },
            });
          }
        } else if (props.meta.interface !== "list") {
          p.push({
            [k]: { _contains: d[key] },
          });
        } else {
          if (/**Array.isArray(d[key]) */ false) {
            d[key].map((obj) => {
              if (parent?.length) {
                generate(props.meta.options.fields, obj, [...parent, key]);
              } else {
                generate(props.meta.options.fields, obj, [key]);
              }
            });
          }
        }
      }
    });
  };

  generate(newSchema, newData);
  return producedQuery;
};

export default searchQueryGenerator;
