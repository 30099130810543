//import antDesign message module
import { message } from "antd";

//import server detilas
import server from "../../../constants/server";

const getUserData = async ([AppState, setAppState], callbacks = []) => {
  //set http request headers
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${AppState.token}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    //send http request and fetch response
    const response = await fetch(`${server}/users/me`, requestOptions);

    //convert response to JSON object
    const result = await response.json();

    //check if server sent errors
    if (result.errors) {
      //check if token expired
      if (
        result.errors[0].message === "Token expired." ||
        result.errors[0].message === "Invalid user credentials."
      ) {
        //delete AppState data
        setAppState({});
        //notify user about token/session expiry
        return message.warning("Session expired, login again to continue");
      }

      //notify user about server sent errors
      return message.warning(result.errors[0].message);
    }

    //add user data to AppState
    setAppState({ ...AppState, user: result.data });

    //check if there are callbacks
    if (callbacks?.length) {
      //run every callback
      callbacks.map((c) => c());
    }
  } catch (e) {
    //notify user about cliend side error
    console.log(e);
    message.error(
      "You are disconnected to the server. Please check your internet connection"
    );
  }
};

export default getUserData;
