import server from "../../../../constants/server";
import { message } from "antd";
const getAddresses = async (
  [AppState, setAppState],
  setState,
  setState2 = () => {}
) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${AppState.token}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${server}/assets/f8946fab-1dce-4194-ae9e-493665365cdb.json`,
      requestOptions
    );
    const result = await response.json();

    if (result.errors) {
      if (
        result.errors[0].message === "Token expired." ||
        result.errors[0].message === "Invalid user credentials."
      ) {
        setAppState({});
        return message.warning("Session expired, login again to continue");
      }
      return message.warning(result.errors[0].message);
    }

    setState(result);
    setState2(result);
  } catch (e) {
    console.log(e);
    message.error(
      "You are disconnected to the server. Please check your internet connection"
    );
  }
};

export default getAddresses;
