import React, { useState, useEffect, useContext } from "react";
import { Form, Select, Input, Button, Tooltip } from "antd";
import occupations from "../functions/occupations.json";
import { LockOutlined, UnlockOutlined } from "@ant-design/icons";
import FormContext from "../../../../contexts/FormContext";
import conditionsManager from "../functions/conditionsManager";
import ModalForMultiSelect from "./ModalForMultiSelect";

function Dropdown({
  pk,
  name,
  label,
  rules,
  isListField,
  instance,
  field,
  formHook,
  findMode,
  rname,
}) {
  const [isOther, setIsOther] = useState(false);
  const [modalVisible, setModalVisibile] = useState(false);
  const [formState, setFormState] = useContext(FormContext);
  const [localRules, setLocalRules] = useState(rules);
  const [visibility, setVisibility] = useState(true);
  const [conditions, setCondition] = useState(field?.meta?.conditions);
  const [options, setOptions] = useState(
    field.meta.options.choices.map((opt) => ({
      label: opt.text,
      value: opt.value,
    }))
  );

  const [allowOther, setAllowOther] = useState(
    field?.meta?.options?.allowOther
  );

  useEffect(() => {
    let option = [...options];

    if (name.includes("occupation") && option.length <= 1) {
      option = occupations.map((item) => ({ label: item, value: item }));
    }

    if (findMode) {
      setLocalRules([]);
      setVisibility(true);
    } else if (conditions) {
      conditionsManager(
        formState,
        conditions,
        true,
        setVisibility,
        rules,
        setLocalRules,
        option,
        setOptions,
        allowOther,
        setAllowOther,
        label
      );
    } else {
      setOptions(option);
    }
  }, [formState]);

  const bactToSelection = () => {
    setIsOther(false);
    if (!isListField) {
      formHook.setFieldsValue({ [name]: null });
    } else {
      const fieldValue = formHook.getFieldValue(rname);
      Object.assign(fieldValue[name[0]], {
        [name[1]]: null,
      });
    }
  };

  const setvalue = (d) => {
    if (visibility) {
      if (instance?.isListField) {
        const fieldValue = formHook.getFieldValue(rname);
        Object.assign(fieldValue[name[0]], {
          [name[1]]: d,
        });
      } else {
        formHook.setFieldsValue({ [name]: d });
      }
    }
  };

  return (
    <>
      {visibility ? (
        <>
          <ModalForMultiSelect
            VisibilityState={[modalVisible, setModalVisibile]}
            AddFunction={setvalue}
            label={label}
            name={name}
          />
          <Form.Item
            key={pk}
            name={name}
            label={
              <span>
                {allowOther ? (
                  <Tooltip title="You can add other values if choices are insufficient">
                    <UnlockOutlined style={{ color: "#7cb305" }} />
                  </Tooltip>
                ) : (
                  <Tooltip title="You must select from the given choices only">
                    <LockOutlined style={{ color: "#d48806" }} />
                  </Tooltip>
                )}{" "}
                {label}
                <br />
                <small>{field.meta.note}</small>
              </span>
            }
            rules={localRules}
            isListField={isListField}
            extra={
              allowOther ? (
                <div style={{ textAlign: "right" }}>
                  or click here:{" "}
                  <a
                    onClick={() => setModalVisibile(true)}
                    style={{ textDecoration: "underline" }}
                  >
                    Others (Specify)
                  </a>
                </div>
              ) : null
            }
          >
            <Select showSearch options={options} />
          </Form.Item>
        </>
      ) : null}
    </>
  );
}

export default Dropdown;
