import server from "../../../../constants/server";
import passkey from "../../../../constants/passkey";
import { message } from "antd";
import { parse } from "json2csv";
import { flatten } from "flat";
import { saveAs } from "file-saver";
import lzwCompress from "lzwcompress";
import JSZip from "jszip";
import { createEncryptor } from "simple-encryptor";
const encryptor = createEncryptor(passkey);

const downloadData = async (
  collectionID,
  [AppState, setAppState],
  findQuery,
  sortQuery = ["-date_created"],
  option = "Excel"
) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${AppState.token}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  if (option !== "Excel") {
    findQuery = "";
  }

  try {
    const response = await fetch(
      `${server}/items/${collectionID}?meta=*&sort=sort,${sortQuery.join(
        ","
      )}&limit=${-1}${
        findQuery.length ? "&filter=" + findQuery : ""
      }&export=json`,
      requestOptions
    );
    let result = await response.json();

    if (result.errors) {
      if (
        result.errors[0].message === "Token expired." ||
        result.errors[0].message === "Invalid user credentials."
      ) {
        setAppState({});
        return message.warning("Session expired, login again to continue");
      }
      return message.warning(result.errors[0].message);
    }

    const reduction = (result) => {
      result.map((row) => {
        Object.keys(row).map((key) => {
          if (
            key.includes("name") ||
            key.includes("user") ||
            key.includes("uploaded") ||
            key.includes("role_visibility") ||
            key === "id" ||
            key === "sort"
          ) {
            delete row[key];
          } else if (
            row[key] &&
            key.includes("number") &&
            (row[key] > 9000000000 || row[key]?.length >= 10)
          ) {
            delete row[key];
          }
        });
      });
    };

    if (option === "Excel") {
      let flat = result.map((row) => {
        return flatten(row);
      });

      reduction(flat);
      const csv = parse(flat);
      const blob = new Blob([csv], { type: "text/csv" });
      saveAs(blob, `${collectionID}.csv`);
    } else {
      const zip = new JSZip();
      const payload = { collectionID, data: result };
      const encrypted = encryptor.encrypt(payload);
      const pack = lzwCompress.pack(encrypted);
      const blob = new Blob([pack], { type: "text/plain;charset=utf-8" });
      zip.file(collectionID + ".swdb", blob);
      zip.generateAsync({ type: "blob" }).then(function (content) {
        // see FileSaver.js
        saveAs(content, collectionID + ".zip");
      });
    }

    //convertRecords(result.data);

    //setState(result);
  } catch (e) {
    console.log(e);
    message.error(
      "You are disconnected to the server. Please check your internet connection"
    );
  }
};

export default downloadData;
