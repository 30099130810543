import server from "../../constants/server";

import { message } from "antd";

const requestChangePassword = async (
  [AppState, setAppState],
  setState,
  callbacks = []
) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${AppState.token}`);
  myHeaders.append("Content-Type", "application/json");

  var raw = {
    email: AppState.user.email,
    reset_url: window.location.origin + "/reset-password",
  };

  raw = JSON.stringify(raw);

  var method = "POST";

  var requestOptions = {
    method: method,
    headers: myHeaders,
    body: raw,
    mode: "cors",
    redirect: "follow",
  };

  try {
    let response = null;
    let result = null;
    response = await fetch(`${server}/auth/password/request`, requestOptions);

    try {
      result = await response.json();
    } catch (e) {}

    if (result?.errors) {
      if (
        result.errors[0].message === "Token expired." ||
        result.errors[0].message === "Invalid user credentials."
      ) {
        setAppState({});
        return message.warning("Session expired, login again to continue");
      }
      return message.warning(result.errors[0].message);
    }
    message.success("Link sent to your email " + AppState.user.email);
    if (callbacks.length) {
      callbacks.map((c) => c());
    }
  } catch (e) {
    console.log(e);
    message.error(
      "You are disconnected to the server. Please check your internet connection"
    );
  }
};

export default requestChangePassword;
