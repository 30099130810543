import React, { useEffect, useState, useContext } from "react";
import { Collapse, Form, Button, Divider } from "antd";
import FormContext from "../../../../contexts/FormContext";
import conditionsManager from "../functions/conditionsManager";
const { Panel } = Collapse;
function Repeater({
  pk,
  fname,
  RepeaterName,
  generateForm,
  field,
  formHook,
  findMode,
}) {
  const [formState, setFormState] = useContext(FormContext);
  const [conditions, setCondition] = useState(field?.meta?.conditions);
  const [visibility, setVisibility] = useState(true);

  useEffect(() => {
    if (findMode) {
      setVisibility(true);
    } else if (conditions) {
      conditionsManager(
        formState,
        conditions,
        true,
        setVisibility,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        RepeaterName
      );
    }
  }, [formState]);

  const scrollToView = (id) => {
    if (id) {
      const k = id.split("?")[0];
      const el = document.getElementById(k + "_here");

      el.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      {visibility ? (
        <>
          <Divider>{RepeaterName}</Divider>
          <Form.List key={pk} name={fname}>
            {(fields, { add, remove }, { errors }) => (
              <>
                <Collapse
                  accordion
                  onChange={scrollToView}
                  isListField={false}
                  className="no-print"
                >
                  {fields.map((fld, index) => (
                    <Panel
                      forceRender={true}
                      key={`${pk}?${index}`}
                      header={`${RepeaterName} #${index + 1}`}
                      extra={
                        <Button
                          isListField={false}
                          onClick={() => remove(fld.name)}
                          className="no-print"
                        >
                          x
                        </Button>
                      }
                      isListField={false}
                      id={`${pk}_here`}
                    >
                      {generateForm(
                        field.meta.options.fields,
                        { ...fld },
                        formHook,
                        findMode,
                        fname
                      )}
                    </Panel>
                  ))}
                </Collapse>
                <br />
                <Button
                  onClick={add}
                  style={{ width: "100%" }}
                  className="no-print"
                >
                  {field.meta.options.addLabel}
                </Button>
                <Divider className="no-print" />
              </>
            )}
          </Form.List>
          <div
            className="print-only"
            style={{
              display: "none",
              border: "2px dashed black",
              padding: "10px",
            }}
          >
            <Form.List key={pk} name={fname}>
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((fld, index) => (
                    <>
                      <h4>{`${RepeaterName} #${index + 1}`}</h4>
                      {generateForm(
                        field.meta.options.fields,
                        { ...fld },
                        formHook,
                        findMode,
                        fname
                      )}
                      {index + 1 < fields.length ? (
                        <hr style={{ border: "2px dashed" }} />
                      ) : null}
                    </>
                  ))}
                </>
              )}
            </Form.List>
          </div>
        </>
      ) : null}
    </>
  );
}

export default Repeater;
