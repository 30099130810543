//import npm modules
import React, { useContext, useEffect, useState } from "react";

import {
  HomeOutlined,
  PieChartOutlined,
  LogoutOutlined,
  ContainerOutlined,
  UserOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

import { Menu, Typography } from "antd";
import { useRouteMatch, Link, useLocation } from "react-router-dom";

//import react context
import AppContext from "../../../contexts/AppContext";

//deconstruct antDesign Module
const { Title } = Typography;

function SideMenu() {
  //set AppState using AppContext
  const [AppState, setAppState] = useContext(AppContext);

  //set current selected menu item
  const [currentKey, setCurrentKey] = useState(1);

  //set path, pathname  and url from react-router-dom hooks
  let { path, url } = useRouteMatch();
  let { pathname } = useLocation();

  //logout function
  const logout = () => {
    //erase all data in AppState
    setAppState({});
  };

  //run on pathname changes
  useEffect(() => {
    //check current pathname
    if (pathname === "/app") {
      //select first menu option
      setCurrentKey(1);
    } else if (pathname.includes("records")) {
      //select second menu option
      setCurrentKey(2);
    } else if (pathname.includes("reports")) {
      //select third menu option
      setCurrentKey(3);
    } else if (pathname.includes("training")) {
      //select fourth menu option
      setCurrentKey(4);
    } else if (pathname.includes("account")) {
      //select fourth menu option
      setCurrentKey(5);
    }
  }, [pathname]);

  return (
    <Menu
      theme="dark"
      defaultSelectedKeys={["1"]}
      selectedKeys={[`${currentKey}`]}
      mode="inline"
      style={{ height: "100%", margin: "0px 0px", padding: "0px 0px" }}
    >
      {/** Side menu logo*/}
      <Title style={{ color: "white", textAlign: "center", marginTop: "20px" }}>
        SWDB
      </Title>
      <Menu.Item key="1" icon={<HomeOutlined />}>
        <Link to={`${url}`}>Home</Link>
      </Menu.Item>
      <Menu.Item key="2" icon={<ContainerOutlined />}>
        <Link to={`${url}/records`}>Records</Link>
      </Menu.Item>
      <Menu.Item key="3" icon={<PieChartOutlined />}>
        <Link to={`${url}/reports`}>Reports</Link>
      </Menu.Item>
      <Menu.Item key="4" icon={<QuestionCircleOutlined />}>
        <Link to={`${url}/training`}>Training</Link>
      </Menu.Item>
      <Menu.Item key="5" icon={<UserOutlined />}>
        <Link to={`${url}/account`}>Account</Link>
      </Menu.Item>
      <Menu.Item key="6" icon={<LogoutOutlined />} onClick={logout}>
        Logout
      </Menu.Item>
    </Menu>
  );
}

export default SideMenu;
