import { Divider } from "antd";
import TextInput from "../formUI_Items/TextInput";
import Dropdown from "../formUI_Items/Dropdown";
import ImageUploader from "../formUI_Items/ImageUploader";
import Repeater from "../formUI_Items/Repeater";
import MultiselectDropdown from "../formUI_Items/MultiselectDropdown";
import AddressCascader from "../formUI_Items/AddressCascader";
import DateInput from "../formUI_Items/DateInput";
import NumberInput from "../formUI_Items/NumberInput";
import RadioButton from "../formUI_Items/RadioButton";
import TimeInput from "../formUI_Items/TimeInput";
import TextArea from "../formUI_Items/TextArea";

function generateForm(schema, instance, formHook, findMode, rname) {
  const nameParser = (name) => {
    let tempName = name
      .split("_")
      .join(" ")
      .replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });

    if (tempName.split(" ")[0] == "Is") {
      tempName = tempName + "?";
    }

    return tempName;
  };

  let items = schema?.filter((field) => !field.meta.hidden);

  if (!instance) {
    items = items.sort((a, b) => (a.meta.sort > b.meta.sort ? 1 : -1));
  }

  const parsedItems = items.map((field, i) => {
    const label = nameParser(field.field);
    let rules = [];
    let key = "";
    let name = "";

    if (!instance) {
      rules = field.schema?.is_nullable
        ? []
        : [{ required: true, message: `Please enter ${label}` }];
      name = field.field;
      key = field.field;
    }

    if (instance) {
      name = [instance.name, field.field];
      key = `${instance.key}_${field.field}`;
    }

    if (findMode) {
      rules = [];
    }
    //interface is a divider

    if (field.meta.interface === "presentation-divider") {
      return (
        <>
          <Divider>{field.meta.options.title}</Divider>{" "}
          {field?.meta?.note ? (
            <div style={{ textAlign: "center" }}>
              <small>{field.meta.note}</small>
              <br />
              <br />
            </div>
          ) : null}
        </>
      );
    }

    //interface is a text input
    if (
      field.meta.interface === "input" &&
      field.type !== "bigInteger" &&
      field.type !== "integer"
    ) {
      return (
        <TextInput
          pk={key}
          name={name}
          label={label}
          field={field}
          rules={rules}
          instance={instance}
          findMode={findMode}
          formHook={formHook}
          rname={rname}
        />
      );
    }

    //interface is a dropdown
    if (field.meta.interface === "select-dropdown") {
      return (
        <Dropdown
          pk={key}
          name={name}
          label={label}
          rules={rules}
          isListField={instance ? true : false}
          instance={instance}
          findMode={findMode}
          formHook={formHook}
          field={field}
          rname={rname}
        />
      );
    }

    if (field.meta.interface === "select-radio") {
      return (
        <RadioButton
          pk={key}
          name={name}
          field={field}
          label={label}
          rules={rules}
          instance={instance}
          formHook={formHook}
          findMode={findMode}
          rname={rname}
        />
      );
    }

    if (field.meta.interface === "datetime" && field.type === "date") {
      return (
        <DateInput
          pk={key}
          name={name}
          label={label}
          field={field}
          rules={rules}
          instance={instance}
          formHook={formHook}
          findMode={findMode}
          rname={rname}
        />
      );
    }

    if (field.meta.interface === "datetime" && field.type === "time") {
      return (
        <TimeInput
          pk={key}
          name={name}
          label={label}
          field={field}
          rules={rules}
          instance={instance}
          findMode={findMode}
          formHook={formHook}
          rname={rname}
        />
      );
    }

    if (field.type === "integer" || field.type === "bigInteger") {
      return (
        <NumberInput
          pk={key}
          name={name}
          label={label}
          field={field}
          rules={rules}
          instance={instance}
          formHook={formHook}
          findMode={findMode}
          rname={rname}
        />
      );
    }

    if (field.meta.interface === "input-code") {
      const test = field.field.split("_");
      const isAddress = test.includes("address");

      if (isAddress && field.meta.options.language === "JSON") {
        return (
          <AddressCascader
            pk={key}
            name={name}
            label={label}
            rules={rules}
            findMode={findMode}
            instance={instance}
            field={field}
            formHook={formHook}
            findMode={findMode}
            rname={rname}
          />
        );
      }
    }

    if (field.meta.interface === "select-multiple-dropdown") {
      return (
        <MultiselectDropdown
          pk={key}
          name={name}
          label={label}
          rules={rules}
          findMode={findMode}
          instance={instance}
          field={field}
          formHook={formHook}
          findMode={findMode}
          rname={rname}
        />
      );
    }

    if (field.meta.interface === "tags") {
      return (
        <MultiselectDropdown
          pk={key}
          name={name}
          label={label}
          rules={rules}
          findMode={findMode}
          instance={instance}
          field={field}
          formHook={formHook}
          findMode={findMode}
          rname={rname}
        />
      );
    }

    if (field.meta.interface === "input-multiline") {
      return (
        <TextArea
          pk={key}
          name={name}
          label={label}
          field={field}
          rules={rules}
          instance={instance}
          formHook={formHook}
          findMode={findMode}
          rname={rname}
        />
      );
    }

    //interface is a file image upload
    if (field.meta.interface === "image") {
      return (
        <ImageUploader
          pk={key}
          name={name}
          label={label}
          rules={rules}
          formHook={formHook}
          findMode={findMode}
          field={field}
          instance={instance}
          rname={rname}
        />
      );
    }

    if (field.meta.interface === "list") {
      return (
        <>
          {findMode ? (
            <>
              <Divider />
              <p style={{ textAlign: "center" }}>
                Cannot find by "{nameParser(field.field)}"
              </p>
              <Divider />
            </>
          ) : (
            <>
              <Repeater
                fname={name}
                pk={key}
                RepeaterName={nameParser(field.field)}
                generateForm={generateForm}
                field={field}
                formHook={formHook}
              />
            </>
          )}
        </>
      );
    }

    //return <pre>{JSON.stringify(field, null, 2)}</pre>;

    return (
      <pre>
        Unsupported Field "{name}" with interface "{field.meta.interface}"
      </pre>
    );
  });

  return parsedItems;
}

export default generateForm;
