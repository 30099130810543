import React, { useState, useEffect, useContext } from "react";
import { Form, Button, Upload, message } from "antd";
import server from "../../../../constants/server";
import AppContext from "../../../../contexts/AppContext";
import uploadFile from "../functions/uploadFile";
import imageCompression from "browser-image-compression";
import FormContext from "../../../../contexts/FormContext";
import conditionsManager from "../functions/conditionsManager";

function ImageUploader({
  pk,
  name,
  label,
  rules,
  formHook,
  findMode,
  field,
  instance,
  rname,
}) {
  const [value, setValue] = useState(null);
  const [appState, setAppState] = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  const [formState, setFormState] = useContext(FormContext);
  const [conditions, setCondition] = useState(field?.meta?.conditions);
  const [localRules, setLocalRules] = useState(rules);
  const [visibility, setVisibility] = useState(true);

  const frontendUpload = async (file, fileList) => {
    const options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 600,
      useWebWorker: true,
    };
    setLoading(true);
    const compressedFile = await imageCompression(file, options);
    const data = await uploadFile([appState, setAppState], compressedFile);
    if (data.id) {
      formHook.setFieldsValue({ [name]: data.id });
      setValue(data.id);
    }
    setLoading(false);
  };

  const clearUpload = () => {
    formHook.setFieldsValue({ [name]: null });
    setValue(null);
  };

  useEffect(() => {
    setValue(formHook.getFieldValue(name));
  });

  useEffect(() => {
    if (findMode) {
      setLocalRules([]);
      setVisibility(true);
    } else if (conditions) {
      conditionsManager(
        formState,
        conditions,
        true,
        setVisibility,
        rules,
        setLocalRules,
        undefined,
        undefined,
        undefined,
        undefined,
        label
      );
    }
  }, [formState]);

  return (
    <>
      {visibility ? (
        <>
          {!value ? (
            <Form.Item
              key={pk}
              name={name}
              label={
                <span>
                  {label}
                  <br />
                  <small>{field.meta.note}</small>
                </span>
              }
              rules={localRules}
              isListField={instance ? true : false}
              valuePropName="portabox"
              hidden={findMode}
            >
              <Upload
                className="no-print"
                portabox={null}
                beforeUpload={(file, fileList) => {
                  if (!file.type.includes("image")) {
                    message.error(`${file.name} is not an image file`);
                    return Upload.LIST_IGNORE;
                  } else {
                    frontendUpload(file, fileList);
                  }
                }}
              >
                <Button loading={loading}>Click to Upload</Button>
              </Upload>
            </Form.Item>
          ) : (
            <Form.Item
              key={pk}
              name={name}
              label={
                <span>
                  {label}
                  <br />
                  <small>{field.meta.note}</small>
                </span>
              }
              rules={localRules}
              isListField={instance ? true : false}
              valuePropName="portabox"
              style={{ textAlign: "center" }}
            >
              <img
                src={`${server}/assets/${value}?access_token=${appState.token}`}
                portabox={value}
                style={{
                  maxWidth: "70%",
                  padding: "20px",
                  maxHeight: "300px",
                  marginTop: "20px",
                  marginBottom: "30px",
                }}
                onDoubleClick={clearUpload}
              />
            </Form.Item>
          )}
        </>
      ) : null}
    </>
  );
}

export default ImageUploader;
