//import npm modules
import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Divider,
  Pagination,
  Dropdown,
  Typography,
  Modal,
  Menu,
} from "antd";
import {
  PlusOutlined,
  SearchOutlined,
  CloudDownloadOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { useParams } from "react-router-dom";

//import custom react contexts
import AppContext from "../../../contexts/AppContext";

//import custom react components
import RecordDrawer from "./drawers/RecordDrawer";
import DataTable from "./tables/dataTable";

//import custom functions
import getCollectionDetail from "./functions/getCollectionDetail";
import getCollectionFields from "./functions/getCollectionFields";
import RecordUiContext from "../../../contexts/RecordUiContext";
import downloadData from "./functions/downloadData";

//destructure antDesign components
const { Title, Paragraph } = Typography;

function RecordUI() {
  //create record drawer state  for drawer visibility
  const [recordDrawer, setRecordDrawer] = useState(false);

  //create pagination state
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 4,
    total: 0,
  });

  //create collection meta state to store meta data
  const [collectionMeta, setCollectionMeta] = useState({});

  //create collection fields state to store list of collection fields data
  const [collectionFields, setCollectionFields] = useState([]);

  //create loading state for loading spinner visibility
  const [loading, setLoading] = useState(false);

  //create find mode state
  const [findMode, setFindMode] = useState(false);

  //create sort query state for storing user sort configuration
  const [sortQuery, setSortQuery] = useState(["-date_created"]);

  //create find query state to store generated query
  const [findQuery, setFindQuery] = useState("");

  //create selected item state
  const [selectedItem, setSelectedItem] = useState({});

  //destrature collectID from useParams hook, from url parameter
  const { collectionID } = useParams();

  //create title state
  const [title, setTitle] = useState(
    //normalize name from "db_name" to "Db Name"
    collectionID
      .split("_")
      .join(" ")
      .replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      })
  );

  //create appState from App Context
  const [appState, setAppState] = useContext(AppContext);

  //handle new record function
  const newRecord = () => {
    //show drawer
    setRecordDrawer(true);

    //set drawer item to blank object
    setSelectedItem({});

    //set find mode to false for state consistency
    setFindMode(false);
  };

  //handle select item function
  const selectItem = (data) => {
    //show drawer
    setRecordDrawer(true);

    //set drawer item to selected data
    setSelectedItem(data);

    //set find mode to false for state consistency
    setFindMode(false);
  };

  //handle export or download

  const dl = async (option) => {
    setLoading(true);
    await downloadData(
      collectionID,
      [appState, setAppState],
      findQuery,
      sortQuery,
      option
    );
    setLoading(false);
  };

  //show export confirmation modal

  const showModal = (option) => {
    const content = (
      <>
        <h3>
          {findQuery.length && option === "Excel"
            ? "Exporting Search Results"
            : "Exporting All Records"}
        </h3>
        <Divider />
        <h4>for {option}</h4>
        {pagination.total >= 1000 ? (
          <>
            <p>
              You are downloading a large dataset of {pagination.total} records.{" "}
              This process may be slow, depending on your hardware or internet
              connection. We recommend finding/searching the records that you
              need first to minimize the dataset.
            </p>
          </>
        ) : null}
        {option === "Excel" ? (
          <>
            Records on names and contact numbers are removed due to data privacy
            concerns.
            <br />
            <br />
            <b>Continue?</b>
          </>
        ) : (
          <>
            You will be downloading an SWDB file for offline submission. This
            option is for coastal/remote users who cannot directly send data to
            the online SWDB. The downloaded file should be mailed to PSWD
            Isabela on any medium: CD, DVD, or Flash Drive. Or, if possible, the
            downloaded SWDB file should be emailed to{" "}
            <a href="mailto:administrator@swdb.one">administrator@swdb.one</a>
            <br />
            <br />
            <b>Continue?</b>
          </>
        )}
      </>
    );
    Modal.confirm({
      cancelText: "No",
      okText: "Yes",
      content: content,
      onOk: () => dl(option),
    });
  };

  //handle search box function
  const openSearch = () => {
    //show drawer
    setRecordDrawer(true);

    //set drawer item to selected data
    setSelectedItem({});

    //set find mode to true
    setFindMode(true);
  };

  //start up effect
  useEffect(() => {
    //start up function
    const startup = async () => {
      //show loading spinner
      setLoading(true);

      //get initial collection detail
      await getCollectionDetail(
        collectionID,
        [appState, setAppState],
        setCollectionMeta
      );

      //get initial collection fields
      await getCollectionFields(
        collectionID,
        [appState, setAppState],
        setCollectionFields
      );

      //hide loading spinner
      setLoading(false);
    };

    //excecute start up function
    startup();
  }, [collectionID]);

  return (
    <div>
      <Title>
        <Paragraph style={{ marginBottom: "0" }}>{title}</Paragraph>
      </Title>
      {collectionMeta?.meta?.note ? (
        <Paragraph ellipsis={{ rows: 1, tooltip: title }}>
          {collectionMeta.meta.note}
        </Paragraph>
      ) : null}
      <Paragraph>
        No. of {findQuery.length ? `Search results` : `Records`}:{" "}
        {pagination.total}
      </Paragraph>
      {loading ? (
        <Button loading>Loading</Button>
      ) : (
        <>
          {collectionFields.map((f) => f.field).includes("uploaded") &&
          collectionFields.map((f) => f.field).includes("role_visibility") ? (
            <>
              <Button type="primary" onClick={newRecord} loading={loading}>
                <PlusOutlined /> Record
              </Button>{" "}
              <Button loading={loading} onClick={openSearch}>
                <SearchOutlined /> Find
              </Button>{" "}
              <Dropdown
                loading={loading}
                overlay={
                  <Menu>
                    <Menu.Item
                      key="excel"
                      onClick={() => showModal("Excel")}
                      disabled={pagination.total <= 0}
                    >
                      {findQuery.length ? "Results" : "Records"} for Excel
                    </Menu.Item>
                    <Menu.Item
                      key="offlineSubmission"
                      onClick={() => showModal("Offline Submission")}
                      disabled={
                        (window.location.hostname !== "localhost" &&
                          window.location.hostname !== "127.0.0.1" &&
                          !window.location.hostname.includes("192.168.")) ||
                        pagination.total <= 0
                      }
                    >
                      For Offline Submission
                    </Menu.Item>
                  </Menu>
                }
                trigger={["click", "hover"]}
              >
                <Button>
                  <CloudDownloadOutlined /> Downloads <DownOutlined />
                </Button>
              </Dropdown>
            </>
          ) : (
            <>
              <Button disabled>"{title}" is not ready for data enty</Button>
              <br />
              <small>Comming Soon</small>
            </>
          )}
        </>
      )}
      <Divider />
      <RecordUiContext.Provider
        value={{
          uiLoading: [loading, setLoading],
          recordDrawer: [recordDrawer, setRecordDrawer],
        }}
      >
        <div>
          {collectionFields.length ? (
            <DataTable
              displayTemplate={collectionMeta?.meta?.display_template}
              selectItem={selectItem}
              schema={collectionFields}
              findQueryState={[findQuery, setFindQuery]}
              sortQueryState={[sortQuery, setSortQuery]}
              paginationState={[pagination, setPagination]}
            />
          ) : null}
        </div>
        <RecordDrawer
          visibilityState={[recordDrawer, setRecordDrawer]}
          schema={collectionFields}
          selectedItemState={[selectedItem, setSelectedItem]}
          findMode={findMode}
          findQueryState={[findQuery, setFindQuery]}
          sortQueryState={[sortQuery, setSortQuery]}
        />
      </RecordUiContext.Provider>
    </div>
  );
}

export default RecordUI;
