//import npm modules
import React, { useContext } from "react";
import { Button, Drawer, Tag, Typography, Grid } from "antd";
import parse from "html-react-parser";

//import server details
import server from "../../../constants/server";

//import react context
import AppContext from "../../../contexts/AppContext";

const { Text } = Typography;
const { useBreakpoint } = Grid;

function ReportDrawer({ selectedReportState = [], visibilityState = [] }) {
  //create visibility state for drawer visibility
  const [visibility, setVisibility] = visibilityState;

  //create state for selected report
  const [selectedReport, setSelectedReport] = selectedReportState;

  //create appState from AppContext
  const [appState, setAppState] = useContext(AppContext);

  //function for closing the report drawer
  const handleClose = () => {
    //clear state for selected report
    setSelectedReport({});

    //close the report drawer
    setVisibility(false);
  };

  //function for opening report link
  const handleOpenLink = () => {
    //open report link url to another tab / window (depending on the browser)
    window.open(selectedReport.link);
  };

  const screens = useBreakpoint();

  return (
    <Drawer
      visible={visibility}
      closable={true}
      width={screens.xs || screens.sm !== screens.lg ? "100vw" : "50vw"}
      onClose={handleClose}
      title={selectedReport.title}
    >
      <div style={{ width: "100%", textAlign: "center" }}>
        {/** Report banner image */}
        <img
          src={`${server}/assets/${selectedReport.banner}?access_token=${appState.token}`}
          style={{ width: "40%" }}
        />
      </div>
      <br />
      <div style={{ textAlign: "center" }}>
        {/** Report tagline */}
        <Text code style={{ fontStyle: "italic" }}>
          "{selectedReport.tagline}"
        </Text>
      </div>
      <br />
      <br />
      {/** Report html content */}
      {selectedReport.content ? parse(selectedReport.content) : null}
      <br />

      {selectedReport.record_tag ? (
        <>
          {/** Report tags */}
          <p>Tags:</p>
          {selectedReport.record_tag.map((t) => (
            <Tag>{t}</Tag>
          ))}
        </>
      ) : null}
      <br />
      <br />
      <br />
      {/** Report link button */}
      <Button style={{ width: "100%" }} type="primary" onClick={handleOpenLink}>
        Go to report
      </Button>
    </Drawer>
  );
}

export default ReportDrawer;
