//import npm modules
import React, { useState, useContext } from "react";
import { Layout, Grid, Affix, Button, Drawer, BackTop } from "antd";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";

import { MenuOutlined } from "@ant-design/icons";

//import custom react components
import Records from "../Records/Records";
import RecordUI from "../Records/RecordUI/RecordUI";
import Home from "../Home/Home";
import Reports from "../reports/Reports";
import SideMenu from "./SideMenu/SideMenu";

//import react context
import AppContext from "../../contexts/AppContext";
import Account from "../Account/Account";
import Training from "../Training/Training";
import Maintenance from "../Maintenance/Maintenance";

//destructure antDesign Layout component
const { Header, Footer, Sider, Content } = Layout;
const { useBreakpoint } = Grid;

function AppContainer() {
  //set collapse state for side menu
  const [collapse, setCollapse] = useState(false);

  //set AppState from AppContext
  const [appState, setAppState] = useContext(AppContext);

  //get current path from useRouteMatch
  let { path } = useRouteMatch();

  //toggle function for side menu collapse
  const collapseSider = () => {
    setCollapse(!collapse);
  };

  //set screen breakpoint
  const screens = useBreakpoint();

  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  return (
    <Layout>
      {screens.lg || screens.xl || screens.xxl ? (
        <Affix offsetTop={true}>
          <Sider
            collapsible
            collapsed={collapse}
            onCollapse={collapseSider}
            style={{ height: "100vh" }}
          >
            <SideMenu />
          </Sider>
        </Affix>
      ) : (
        <>
          <Affix
            offsetTop={true}
            style={{ marginTop: "10px", marginLeft: "10px" }}
          >
            <Button onClick={showDrawer}>
              <MenuOutlined /> SWDB
            </Button>
          </Affix>
          <Drawer
            placement="left"
            closable={false}
            onClose={onClose}
            visible={visible}
            bodyStyle={{ padding: "0px" }}
            onClick={onClose}
          >
            <SideMenu />
          </Drawer>
        </>
      )}
      <Layout
        style={{ padding: "20px", paddingLeft: "2vw", maxHeight: "100vh" }}
      >
        <Content>
          <Switch>
            <Route exact path={`${path}/records/:collectionID`}>
              {/**Databse records page */}
              <RecordUI />
            </Route>
            <Route exact path={`${path}/records`}>
              {/** Database list page */}
              <Records />
            </Route>
            <Route exact path={`${path}/reports`}>
              {/** Reports page */}
              {/* window.location.hostname !== "localhost" &&
              window.location.hostname !== "127.0.0.1" &&
              !window.location.hostname.includes("192.168.") ? (
                <Reports />
              ) : (
                <Maintenance
                  title="Reports not available for offline use"
                  tagline="Please submit your records to PSWD Isabela and use the online version of this software to view"
                  timeline="This feature will be available soon..."
                  showRefresh={false}
                  image={`${window.location.origin}/what.gif`}
                />
              ) */}
              <Reports />
            </Route>
            <Route exact path={`${path}/training`}>
              {/** Trining page */}
              <Training />
            </Route>
            <Route exact path={`${path}/account`}>
              {/** Account page */}
              <Account />
            </Route>
            <Route exact path={`${path}`}>
              {/** Home page */}
              <Home />
            </Route>
          </Switch>
        </Content>
        <BackTop />
      </Layout>
    </Layout>
  );
}

export default AppContainer;
