//import ant design message module
import { message } from "antd";

//import server detials
import server from "../constants/server";

//the refresher function
const refresher = async (refreshToken, [AppState, setAppState]) => {
  //defining headers
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  //setting refresh token
  var raw = JSON.stringify({
    refresh_token: refreshToken,
  });

  //setting http request options
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  //try function for catching http errors
  try {
    //fetching new token using refresh token
    const response = await fetch(`${server}/auth/refresh`, requestOptions);

    //passing http response to variable result
    const result = await response.json();

    //cheking if result contains error message from server
    if (result.errors) {
      return message.warning(result.errors[0].message);
    }

    //checking if result contains new token
    if (result.data.access_token) {
      //set new token and new refresh token to AppState
      setAppState({
        ...AppState,
        token: result.data.access_token,
        refresh_token: result.data.refresh_token,
      });
    } else {
      //alert user if result does not contain any expected result from server
      message.error("Server tamper detected, please contact admin");
    }
  } catch (e) {
    //alert user if errors client side errors detected
    console.log(e);
    message.error(
      "You are disconnected to the server. Please check your internet connection"
    );
  }
};

export default refresher;
