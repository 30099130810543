import moment from "moment";
const dataCleaner = (schema, data) => {
  const newData = { ...data };
  const localSchema = [...schema];

  const convertRecords = (sch, data) => {
    sch.map((f) => {
      const test = f.field.split("_");
      if (
        f.type === "json" &&
        test[test.length - 1] === "address" &&
        f.meta.options.language === "JSON"
      ) {
        if (Array.isArray(data?.[f.field])) {
          data[f.field] = {
            region: data[f.field][0],
            province: data[f.field][1],
            municipality: data[f.field][2],
            barangay: data[f.field][3],
          };
        }
      } else if (
        f.type === "date" ||
        f.type === "timestamp" ||
        f.type === "datetime"
      ) {
        if (Array.isArray(data)) {
          data.map((obj) => {
            if (obj?.[f.field]) {
              obj[f.field] = moment(obj[f.field]).format("YYYY-MM-DD");
            }
          });
        } else {
          if (data?.[f.field]) {
            data[f.field] = moment(data[f.field]).format("YYYY-MM-DD");
          }
        }
      } else if (f.meta.interface === "list") {
        if (data[f.field]?.length && Array.isArray(data[f.field])) {
          data[f.field].map((obj) => {
            convertRecords(f.meta.options.fields, obj);
          });
        }
      }
    });
  };

  const removeExtraFields = (sch, data) => {
    Object.keys(data).map((prop) => {
      const field = sch.filter((s) => s.field === prop);
      if (!field.length) {
        delete data[prop];
      } else if (field[0].meta.interface === "list") {
        if (data[prop]?.length && Array.isArray(data[prop])) {
          data[prop].map((obj) => {
            removeExtraFields(field[0].meta.options.fields, obj);
          });
        }
      }
    });
  };

  removeExtraFields(localSchema, newData);
  convertRecords(localSchema, newData);
  return newData;
};

export default dataCleaner;
