import React, { useState, useEffect, useContext } from "react";
import { Form, Select, Input, Button, Tooltip } from "antd";
import occupations from "../functions/occupations.json";
import FormContext from "../../../../contexts/FormContext";
import conditionsManager from "../functions/conditionsManager";
import { LockOutlined, UnlockOutlined } from "@ant-design/icons";
import ModalForMultiSelect from "./ModalForMultiSelect";

function MultiselectDropdown({
  pk,
  name,
  label,
  rules,
  instance,
  field,
  findMode,
  formHook,
  rname,
}) {
  const [formState, setFormState] = useContext(FormContext);
  const [localRules, setLocalRules] = useState(rules);
  const [visibility, setVisibility] = useState(true);
  const [conditions, setCondition] = useState(field?.meta?.conditions);
  const [modalVisible, setModalVisibile] = useState(false);

  const [options, setOptions] = useState(
    field.meta.options.choices.map((opt) => ({
      label: opt.text,
      value: opt.value,
    }))
  );

  const [allowOther, setAllowOther] = useState(
    field?.meta?.options?.allowOther
  );

  useEffect(() => {
    if (name.includes("occupation") && options.length <= 1) {
      setOptions(occupations.map((item) => ({ label: item, value: item })));
    }
  }, []);

  useEffect(() => {
    if (findMode) {
      setLocalRules([]);
      setVisibility(true);
    } else if (conditions) {
      conditionsManager(
        formState,
        conditions,
        true,
        setVisibility,
        rules,
        setLocalRules,
        options,
        setOptions,
        allowOther,
        setAllowOther,
        label
      );
    }
  }, [formState]);

  const setvalue = (v) => {
    if (visibility) {
      if (instance?.isListField) {
        const fieldValue = formHook.getFieldValue(rname);
        const oldValue = fieldValue?.[name?.[0]]?.[name?.[1]]
          ? [...fieldValue?.[name?.[0]]?.[name?.[1]]]
          : [];
        if (!oldValue.includes(v)) {
          oldValue.push(v);
        }
        Object.assign(fieldValue[name[0]], {
          [name[1]]: oldValue,
        });
      } else {
        let oldValue = formHook.getFieldValue(name)
          ? [...formHook.getFieldValue(name)]
          : [];
        if (!oldValue.includes(v)) {
          oldValue.push(v);
        }
        formHook.setFieldsValue({ [name]: oldValue });
      }
    }
  };

  return (
    <>
      {visibility ? (
        <>
          <Form.Item
            key={pk}
            name={name}
            label={
              <span>
                {allowOther ? (
                  <Tooltip title="You can add other values if choices are insufficient">
                    <UnlockOutlined style={{ color: "#7cb305" }} />
                  </Tooltip>
                ) : (
                  <Tooltip title="You must select from the given choices only">
                    <LockOutlined style={{ color: "#d48806" }} />
                  </Tooltip>
                )}{" "}
                {label}
                <br />
                <small>{field.meta.note}</small>{" "}
              </span>
            }
            rules={localRules}
            isListField={instance ? true : false}
            extra={
              allowOther ? (
                <div style={{ textAlign: "right" }}>
                  or click here:{" "}
                  <a
                    onClick={() => setModalVisibile(true)}
                    style={{ textDecoration: "underline" }}
                  >
                    Others (Specify)
                  </a>
                </div>
              ) : null
            }
          >
            <Select
              showSearch
              options={options}
              mode={allowOther ? "tags" : "multiple"}
            />
          </Form.Item>
          <ModalForMultiSelect
            VisibilityState={[modalVisible, setModalVisibile]}
            AddFunction={setvalue}
            label={label}
            name={name}
          />
        </>
      ) : null}
    </>
  );
}

export default MultiselectDropdown;
