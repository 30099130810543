//import npm modules
import React, { useState, useEffect, useContext, useRef } from "react";
import { Form, Drawer, Button, Spin, Popconfirm, message, Grid } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import ReactToPrint from "react-to-print";
import { useParams } from "react-router-dom";
import moment from "moment";

//import custom functions
import generateForm from "../functions/generateForm";
import dataCleaner from "../functions/dataCleaner";
import sendData from "../functions/sendData";
import deleteItem from "../functions/deleteItem";
import searchQueryGenerator from "../functions/generateSearchQuery";

//import custom react contexts
import AppContext from "../../../../contexts/AppContext";
import FormContext from "../../../../contexts/FormContext";
import RecordUiContext from "../../../../contexts/RecordUiContext";

const { useBreakpoint } = Grid;

function RecordDrawer({
  schema = [],
  visibilityState = [],
  selectedItemState = [],
  findMode = false,
  findQueryState = [],
  sortQueryState = [],
}) {
  const screens = useBreakpoint();

  const componentRef = useRef();

  const [formState, setFormState] = useState({});

  const [sortQuery, setSortQuery] = sortQueryState;

  //create appState from imported appContext
  const [appState, setAppState] = useContext(AppContext);

  //destructure selectedItem state
  const [selectedItem, setSelectedItem] = selectedItemState;

  //destructure recordDrawer state from visibilityState
  const [recordDrawer, setRecordDrawer] = visibilityState;

  //create loading state for loading spinner visibility
  const { uiLoading } = useContext(RecordUiContext);
  const [loading, setLoading] = uiLoading;

  //create formItems state for storing form fields
  const [formItems, setFormItems] = useState([]);

  //destructure form form useForm hook
  const [form] = Form.useForm();

  //destructure collection id from useParams hook, URL parameter
  const { collectionID } = useParams();

  //destructuring findQuery state (storage for query string)
  const [findQuery, setFindQuery] = findQueryState;

  //create filterData state for storing search query thru find mode using drawer as UI
  const [filterData, setFilterData] = useState({});

  const onFormValueChange = (cv, av) => {
    setFormState(av);
  };

  //function for closing the drawer from view
  const onClose = () => {
    //closing record drawer
    setRecordDrawer(false);

    //deselecting item from state
    setSelectedItem({});

    //reseting form fields value
    form.resetFields();
  };

  //create a name parser for print title
  const nameParser = (name) => {
    return name
      .split("_")
      .join(" ")
      .replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
  };

  //function for handling deletion of record/entry
  const deleteEntry = async () => {
    //show loading spinner
    setLoading(true);

    //send delete request and wait for response
    await deleteItem(collectionID, [appState, setAppState], selectedItem.id);

    //hide loading spinner
    setLoading(false);

    //close drawer
    onClose();
  };

  //effect for generating form items for record drawer
  useEffect(() => {
    setFormItems(generateForm(schema, null, form, findMode));
  }, [schema, findMode]);

  //function for focusing scroll height to an element in the record drawer
  const scrollToView = (id) => {
    if (id) {
      //selecting the element from the parameter
      const el = document.getElementById(id);
      if (el) {
        //scrolling element to view
        el.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  //effect for resetting scroll height (to top)
  useEffect(() => {
    scrollToView("top");
    setFormState(selectedItem);
  }, [recordDrawer]);

  //effect for converting record data to view
  useEffect(() => {
    //cloning selected item state
    const datA = { ...selectedItem };

    //cloning schema state
    const localSchema = [...schema];

    //function for converting records
    const convertRecords = (sch, data) => {
      //mapping schema
      sch.map((f) => {
        //test field names for conditions
        const test = f.field.split("_");

        //checking if field is an address holder (special)
        if (
          f.type === "json" &&
          test[test.length - 1] === "address" &&
          f.meta.options.language === "JSON"
        ) {
          //check if data object contains property of province
          if (data?.[f.field]?.province) {
            //convert address from object into an array for joining
            const arry = [];
            Object.keys(data[f.field]).map((k) => {
              arry.push(data[f.field][k]);
            });
            data[f.field] = arry;
          }
        }

        //checking if field is an dateTime
        else if (
          f.type === "date" ||
          f.type === "timestamp" ||
          f.type === "datetime"
        ) {
          //checking if field value is an array
          if (Array.isArray(data)) {
            //mapping data for property with value
            data.map((obj) => {
              if (obj?.[f.field]) {
                //convert value to moment object
                obj[f.field] = moment(obj[f.field]);
              }
            });
          } else {
            //checking if property contains value
            if (data?.[f.field]) {
              //convert value to moment object
              data[f.field] = moment(data[f.field]);
            }
          }
        }

        //cheking if field is repeater and has json value
        else if (f.type === "json" && f.meta.interface === "repeater") {
          //this function will repeat
          convertRecords(f.meta.options.fields, data[f.field]);
        }
      });
    };

    //check if find mode is active
    if (findMode) {
      //setting form fields value to filtered data
      form.setFieldsValue({ ...filterData });
    } else {
      //converting selected item value to record drawer
      convertRecords(localSchema, datA);

      //setting form fields value to converted record
      form.setFieldsValue({ ...datA });
    }
  }, [selectedItem, findMode]);

  //effect for checking filterData state changfes
  useEffect(() => {
    //reset findQuery state whenever filterData has no prop/value
    if (!Object.keys(filterData)?.length) {
      setFindQuery("");
    }
  }, [filterData]);

  //function to handle clearing filterData state
  const clearFindData = () => {
    setFilterData({});
    onClose();
  };

  //function for handling form data submit
  const formFinish = async (d) => {
    if (!loading) {
      //show loading spinner
      setLoading(true);

      //checking if data is for updating
      if (selectedItem.id) {
        //clean data for server
        const cleanedData = dataCleaner(schema, d);

        //send cleaned data to server
        await sendData(
          collectionID,
          [appState, setAppState],
          [JSON.stringify(cleanedData), selectedItem.id]
        );
      }

      //checking if data is for finding
      else if (findMode) {
        // set filter data state to submitted data (consistency)
        setFilterData(d);

        // clean data before sending to server
        const cleanedData = dataCleaner(schema, d);

        //generate search query
        const searchQuery = searchQueryGenerator(schema, cleanedData);

        //setFindQuery state to the stringified & generated search query
        setFindQuery(`${JSON.stringify(searchQuery)}`);
      }

      //otherwise create new record
      else {
        //send new record to the server, cleaning is done automatically
        await sendData(
          collectionID,
          [appState, setAppState],
          JSON.stringify(dataCleaner(schema, d))
        );
      }

      //close drawer
      onClose();

      //reset form value
      form.resetFields();

      //hiding loading spinner
      setLoading(false);
    }
  };

  const finishFailed = ({ errorFields }) => {
    message.warning(errorFields[0].errors[0] + ".");
  };

  return (
    <Drawer
      title={
        findMode
          ? "Find Record"
          : selectedItem.id
          ? "Viewing Record"
          : "New Record"
      }
      maskClosable={false}
      onClose={onClose}
      visible={recordDrawer}
      width={screens.xs || screens.sm !== screens.lg ? "100vw" : "50vw"}
    >
      <div ref={componentRef} className="doc">
        <Spin spinning={loading}>
          <div
            className="print-title"
            style={{
              textAlign: "center",
              display: "none",
              marginBottom: "27px",
              border: "3px solid black",
              padding: "7px",
              backgroundColor: "lightgray",
            }}
          >
            <h1 style={{ marginBottom: "0px" }}>{nameParser(collectionID)}</h1>
            <h4>{appState.roleData.name}</h4>
            <span>Printed by: {appState.user.email}</span>
            <br />
            <span style={{ fontWeight: "bold" }}>Record Printout</span>
          </div>
          <FormContext.Provider value={[formState, setFormState]}>
            <Form
              layout="vertical"
              form={form}
              onFieldsChange={(v) => {}}
              onFinish={formFinish}
              onFinishFailed={finishFailed}
              onValuesChange={onFormValueChange}
              id="top"
            >
              {[...formItems]}
              <Form.Item className="no-print">
                <Button
                  htmlType="submit"
                  type="primary"
                  loading={loading}
                  style={{ marginRight: "7px" }}
                >
                  {findMode ? "Search" : "Save"}
                </Button>
                {selectedItem.id ? (
                  <Popconfirm
                    title="Are you sure to delete this entry?"
                    onConfirm={deleteEntry}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button loading={loading}>Delete</Button>
                  </Popconfirm>
                ) : null}
                {findMode ? (
                  <Button onClick={clearFindData} loading={loading}>
                    Clear Filters
                  </Button>
                ) : null}
              </Form.Item>
            </Form>
          </FormContext.Provider>
          <ReactToPrint
            trigger={() => (
              <Button
                className="no-print"
                style={{ width: "100%" }}
                type="dashed"
                icon={<PrinterOutlined />}
              >
                Print this out!
              </Button>
            )}
            content={() => componentRef.current}
            pageStyle={`
        @media print {
          .ant-form-item {
            break-inside: avoid;
          }

          .print-only {
            display: block !important;
          }

          .doc {
            margin: 50px !important;
          }
          
          .no-print, .no-print *
          {
              display: none !important;
          }

          .ant-collapse-content-hidden  {
            display: block !important;
          } 

          .print-title {
            display: block !important;
          }

          .ant-radio-wrapper-checked {
            text-decoration-line: underline;
            text-decoration-style: double;
            font-weight: bold;
            font-size: 50px;
            padding: 7px
          }
        }
                   
        `}
          />
        </Spin>
      </div>
    </Drawer>
  );
}

export default RecordDrawer;
