import React from "react";
import { Typography, Divider, Row, Col } from "antd";
import DeviceDetector from "device-detector-js";

const deviceDetector = new DeviceDetector();
const userAgent = window.navigator.userAgent;
const device = deviceDetector.parse(userAgent);
const { Title } = Typography;
function SetDNS() {
  const renderSwitch = () => {
    switch (device.os.name) {
      case "Windows":
        return (
          <Row>
            <Col span={8} xs={24} sm={24} md={12} xl={8}>
              <Title level={4}>(Windows) How you can fix this?</Title>
              <p>Just follow these three simple steps:</p>
              <ol>
                <li>
                  Download this <a href="/network-setup.bat">file.</a>
                </li>
                <li>Run the downloaded file as administrator.</li>
                <li>
                  Re-open your browser and go to{" "}
                  <a href={`${window.location.origin}`}>swdb.one</a>{" "}
                </li>
              </ol>
            </Col>
            <Col span={16} xs={24} sm={24} md={12} xl={16}>
              Visual Demonstration <br />
              <img
                src="/dnsSetup.gif"
                alt="tutorial"
                style={{ width: "90%", border: "3px dashed grey" }}
              />
            </Col>
          </Row>
        );

      case "Mac":
        return (
          <Row>
            <Col span={16} xs={24} sm={24} md={12} xl={16}>
              <Title level={4}>(Mac OS) How you can fix this?</Title>
              <p>Just follow these three simple steps:</p>
              <ol>
                <li>Set your primary DNS to 8.8.8.8</li>
                <li>Set your secondary DNS to 8.8.4.4</li>
                <li>
                  Re-open your browser and go to{" "}
                  <a href={`${window.location.origin}`}>swdb.one</a>{" "}
                </li>
              </ol>
            </Col>
            <Col span={8} xs={24} sm={24} md={12} xl={8}>
              <Title level={4}>Too complicated?</Title>
              <p>&nbsp;&nbsp;&nbsp;Use a Windows computer.</p>
            </Col>
          </Row>
        );

      case "iOS":
        return (
          <Row>
            <Col span={16} xs={24} sm={24} md={12} xl={16}>
              <Title level={4}>(iPhone iPad) How you can fix this?</Title>
              <p>Just follow these three simple steps:</p>
              <ol>
                <li>Turn off your Wi-Fi.</li>
                <li>Turn on your mobile (load required).</li>
                <li>
                  Re-open your browser and go to{" "}
                  <a href={`${window.location.origin}`}>swdb.one</a>{" "}
                </li>
              </ol>
            </Col>
            <Col span={8} xs={24} sm={24} md={12} xl={8}>
              <Title level={4}>No load? or using a Wi-Fi only Ipad?</Title>
              <p>&nbsp;&nbsp;&nbsp;Use a Windows computer.</p>
            </Col>
          </Row>
        );

      case "Android":
        return (
          <Row>
            <Col span={16} xs={24} sm={24} md={12} xl={16}>
              <Title level={4}>(Android) How you can fix this?</Title>
              <p>Just follow these three simple steps:</p>
              <ol>
                <li>Turn-off your Wi-Fi.</li>
                <li>Turn-on your mobile data (load required).</li>
                <li>
                  Re-open your browser and go to{" "}
                  <a href={`${window.location.origin}`}>swdb.one</a>{" "}
                </li>
              </ol>
            </Col>
            <Col span={8} xs={24} sm={24} md={12} xl={8}>
              <Title level={4}>No load? or using a Wi-Fi only tablet?</Title>
              <p>&nbsp;&nbsp;&nbsp;Use a Windows computer.</p>
            </Col>
          </Row>
        );
    }
  };

  return (
    <div
      style={{
        paddingTop: "10vh",
        paddingBottom: "10vh",
        paddingLeft: "10vw",
        paddingRight: "10vw",
      }}
    >
      <Title>✅ DNS Setup for SWDB</Title>
      <Divider />
      <Title level={4}>Why are you here?</Title>
      <p>
        Your internet provider's DNS seems not to be working well with our
        secure network.
      </p>
      <Divider />
      {renderSwitch()}
      <Divider />
      <Title level={4}>Still not working?</Title>
      <ol>
        <li>Restart your device.</li>
        <li>
          Re-open your browser and go to{" "}
          <a href={`${window.location.origin}`}>swdb.one</a>{" "}
        </li>
      </ol>
      <Divider />
      <Title level={4}>😭 Hindi talaga siya gumagana fren?</Title>
      <p>Please contact PSWD Isabela for technical assistance.</p>
    </div>
  );
}

export default SetDNS;
