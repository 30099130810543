//import npm modules
import React, { useState, useEffect } from "react";
import { AES, enc } from "crypto-js";
import server from "./constants/server";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

//import ant design css
import "antd/dist/antd.css";

//import custom react components
import Login from "./pages/login/Login";
import AppContainer from "./pages/AppContainer/AppContainer";

//import context
import AppContext from "./contexts/AppContext";

//import functions
import refresher from "./functions/refreshToken";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import getRole from "./functions/getRole";
import SetDNS from "./pages/SetDNS/SetDNS";
import Maintenance from "./pages/Maintenance/Maintenance";

// define AppState to local storage as "store"
let store = {};

// try to decrypt store from localstorage
try {
  store = JSON.parse(
    AES.decrypt(localStorage.getItem("store"), server).toString(enc.Utf8)
  );
} catch (e) {
  store = {};
}

function App() {
  //set AppState values from store object
  const [AppState, setAppState] = useState(store);

  //add appState to local storage
  useEffect(() => {
    localStorage.setItem(
      "store",
      AES.encrypt(JSON.stringify(AppState), server).toString()
    );
  }, [AppState]);

  //Detect AppState changes
  useEffect(() => {
    //check refresh token state
    if (AppState?.refresh_token) {
      //refresh token every 2 hours
      const timerCTR = 60000 * 60 * 2;
      let interval = setInterval(function () {
        refresher(AppState.refresh_token, [AppState, setAppState]);
      }, timerCTR);
      return () => clearInterval(interval);
    }
  }, [AppState]);

  //Get role data

  useEffect(() => {
    if (AppState?.user?.role && !AppState.roleData) {
      getRole(AppState.user.role, [AppState, setAppState]);
    }
  }, [AppState]);

  return (
    <Router>
      <Switch>
        <AppContext.Provider value={[AppState, setAppState]}>
          <Route path="/app">
            {/** App page */}
            {/** check appState then redirect */}
            {!AppState.token ? <Redirect to="/" /> : <AppContainer />}
          </Route>
          <Route path="/reset-password">
            {/** App password reset page */}
            <ResetPassword />
          </Route>
          <Route path="/setdns">
            <SetDNS />
          </Route>
          <Route path="/admin/accept-invite">
            {/** App password reset page */}
            <ResetPassword />
          </Route>
          <Route path="/maintenance">
            <Maintenance />
          </Route>
          <Route path="/" exact>
            {/** Login page */}
            {/** check appState then redirect */}
            {AppState.token ? <Redirect to="/app" /> : <Login />}
          </Route>
        </AppContext.Provider>
      </Switch>
    </Router>
  );
}

export default App;
