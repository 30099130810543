//import server details
import server from "../../../constants/server";

//import antDesign message module
import { message } from "antd";

const getCollection = async ([AppState, setAppState], setState) => {
  //set request headers
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${AppState.token}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    //send http request and wait for reponse
    const response = await fetch(
      `${server}/collections?limit=-1`,
      requestOptions
    );

    //parse response body to JSON object
    const result = await response.json();

    //check if reponse contains errors
    if (result.errors) {
      //check error is caused by an expired token
      if (
        result.errors[0].message === "Token expired." ||
        result.errors[0].message === "Invalid user credentials."
      ) {
        //clear app state data
        setAppState({});

        //notify user about the expired token
        return message.warning("Session expired, login again to continue");
      }

      //notify user about the error
      return message.warning(result.errors[0].message);
    }

    //filter system collection from the response
    const data = result.data.filter(
      (collection) =>
        !(
          collection.collection.includes("directus_") ||
          collection.collection.includes("system_")
        )
    );

    //data to state
    setState(data);
  } catch (e) {
    //notify user about client side errors
    console.log(e);
    message.error(
      "You are disconnected to the server. Please check your internet connection"
    );
  }
};

export default getCollection;
