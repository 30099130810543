//import npm modules
import React from "react";
import { Spin, Typography, Divider } from "antd";

//import custom react components
import ReportList from "./list/ReportList";

//deconstruct antDesign modules
const { Title } = Typography;

function Reports() {
  return (
    <div>
      {/** Page title */}
      <Title>Reports</Title>
      {/** Page tagline */}
      <Typography>Your data collectively aggregated with us.</Typography>
      <Divider />
      {/** custom react component for report list */}
      <ReportList />
    </div>
  );
}

export default Reports;
