import server from "../../constants/server";
import { message } from "antd";

const changePassword = async (raw, path, callbacks = []) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  raw = JSON.stringify(raw);

  var method = "POST";

  var requestOptions = {
    method: method,
    headers: myHeaders,
    body: raw,
    mode: "cors",
    redirect: "follow",
  };

  try {
    let response = null;
    let result = null;

    try {
      response = path?.includes("invite")
        ? await fetch(`${server}/users/invite/accept`, requestOptions)
        : await fetch(`${server}/auth/password/reset`, requestOptions);

      result = await response.json();
    } catch (e) {
      response = path?.includes("invite")
        ? await fetch(`${server}/users/invite/accept`, requestOptions)
        : await fetch(`${server}/auth/password/reset`, requestOptions);

      result = await response.text();
    }

    if (result.errors) {
      if (
        result.errors[0].message === "Token expired." ||
        result.errors[0].message === "Invalid user credentials."
      ) {
        return message.warning("Session expired, login again to continue");
      }
      return message.warning(result.errors[0].message);
    }
    if (response.ok) {
      message.success("Password Changed");
      if (callbacks.length) {
        callbacks.map((c) => c());
      }
    }
  } catch (e) {
    console.log(e);
    message.error(
      "You are disconnected to the server. Please check your internet connection"
    );
  }
};

export default changePassword;
