//import npm modules
import React, { useContext, useState, useEffect } from "react";
import { Form, Input, Button, Typography, message, Spin } from "antd";
import { useHistory } from "react-router-dom";
import svgCaptcha from "svg-captcha-browser";
import parse from "html-react-parser";
//import custom functions
import login from "./functions/handleLogin";

//import react context
import AppContext from "../../contexts/AppContext";
import server from "../../constants/server";

//deconstruct antDesign module
const { Title } = Typography;

function Login() {
  const [captcha, setCaptcha] = useState({});
  //define antDesign Form hook
  const [form] = Form.useForm();

  let history = useHistory();

  //define AppState from AppContext
  const [AppState, setAppState] = useContext(AppContext);

  //define loading state
  const [loading, setLoading] = useState(false);

  //captcha generator

  const generateCaptcha = () => {
    svgCaptcha
      .loadFont(`${window.location.origin}/comismsh.ttf`)
      .then(() => {
        let captcha = svgCaptcha.createMathExpr({
          mathMin: 10,
          mathMax: 50,
          mathOperator: "+",
          noise: 7,
          color: true,
          fontSize: 80,
        });
        setCaptcha(captcha);
        // {data: '<svg.../svg>', text: 'abcd'}
      })
      .catch((e) => {
        //加载字体出错
        console.log(e);
      });
    form.resetFields();
  };

  //check if server live

  useEffect(() => {
    generateCaptcha();
    const checker = async () => {
      try {
        await fetch(server);
      } catch (e) {
        history.push("/maintenance");
      }
    };
    checker();
  }, [history]);

  const submit = async (data) => {
    if (!loading) {
      let { email, password, sum } = data;

      if (sum === captcha.text) {
        setLoading(true);
        await login(
          { email: email.trim(), password },
          setAppState,
          () => {
            history.push("/setdns");
          },
          () => {
            generateCaptcha();
          }
        );
        setLoading(false);
      } else {
        generateCaptcha();
        message.warning("Wrong answer for math exam!");
      }
    }
  };

  return (
    <div
      style={{
        margin: "0 auto",
        width: "50vw",
        maxHeight: "100vh",
        height: "100vh",
        paddingTop: "6vh",
      }}
    >
      {/** Login header */}
      <div style={{ textAlign: "center" }}>
        <b style={{ fontSize: "5em" }}>SWDB</b>
        <p>Please login to continue</p>
      </div>
      <Form
        form={form}
        layout="vertical"
        onSubmitCapture={(e) => e.preventDefault()}
        onFinish={submit}
      >
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, message: "Please enter your email" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[{ required: true, message: "Please enter your password" }]}
        >
          <Input type="password" />
        </Form.Item>
        <div
          style={{
            textAlign: "center",
            border: "2px dashed black",
            backgroundColor: "white",
            marginBottom: "10px",
          }}
        >
          {captcha.data ? (
            parse(captcha.data)
          ) : (
            <Spin>"loading numbers..."</Spin>
          )}
        </div>

        <Form.Item
          name="sum"
          label="Math exam!"
          rules={[{ required: true, message: "Please enter the sum." }]}
          extra="Prove you are human! Find the sum of two numbers above."
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Login;
