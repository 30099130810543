import server from "../../../../constants/server";
import { message } from "antd";
const getCollectionFields = async (
  collectionID,
  [AppState, setAppState],
  setState,
  callbacks = []
) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${AppState.token}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${server}/fields/${collectionID}`,
      requestOptions
    );
    const result = await response.json();
    if (result.errors) {
      if (
        result.errors[0].message === "Token expired." ||
        result.errors[0].message === "Invalid user credentials."
      ) {
        setAppState({});
        return message.warning("Session expired, login again to continue");
      }
      return message.warning(result.errors[0].message);
    }
    setState(result.data);
    if (callbacks?.length) {
      callbacks.map((c) => c());
    }
  } catch (e) {
    console.log(e);
    message.error(
      "You are disconnected to the server. Please check your internet connection"
    );
  }
};

export default getCollectionFields;
