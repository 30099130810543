import server from "../../../../constants/server";
import { message } from "antd";

const sendData = async (
  collectionID,
  [AppState, setAppState],
  data,
  callbacks = []
) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${AppState.token}`);
  myHeaders.append("Content-Type", "application/json");

  var raw = data;
  var method = "POST";

  if (Array.isArray(data)) {
    raw = data[0];
    method = "PATCH";
  } else {
    raw = JSON.parse(raw);
    raw.role_visibility = AppState.user.role;
    raw = JSON.stringify(raw);
  }

  var requestOptions = {
    method: method,
    headers: myHeaders,
    body: raw,
    mode: "cors",
    redirect: "follow",
  };

  try {
    let response = null;
    if (Array.isArray(data)) {
      response = await fetch(
        `${server}/items/${collectionID}/${data[1]}`,
        requestOptions
      );
    } else {
      response = await fetch(`${server}/items/${collectionID}`, requestOptions);
    }

    const result = await response.json();

    if (result.errors) {
      if (
        result.errors[0].message === "Token expired." ||
        result.errors[0].message === "Invalid user credentials."
      ) {
        setAppState({});
        return message.warning("Session expired, login again to continue");
      }
      return message.warning(result.errors[0].message);
    }
    if (result.data) {
      message.success("Saved");
      if (callbacks.length) {
        callbacks.map((c) => c());
      }
    }
  } catch (e) {
    console.log(e);
    message.error(
      "You are disconnected to the server. Please check your internet connection"
    );
  }
};

export default sendData;
