//import npm modules
import React, { useState, useEffect, useContext } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Spin,
  Pagination,
  Avatar,
  Typography,
  Tag,
  Input,
  Grid,
} from "antd";
import socketIOClient from "socket.io-client";
import { InfoCircleOutlined } from "@ant-design/icons";

//import react contexts
import AppContext from "../../../contexts/AppContext";

//import custom react components
import getReportList from "../functions/getReportList";
import ReportDrawer from "../drawers/ReportDrawer";

//import socket server details
import socket from "../../../constants/socket";

//deconstruct antDesign module
const { Paragraph } = Typography;
const { useBreakpoint } = Grid;

function ReportList() {
  const screens = useBreakpoint();

  // create AppState from AppContext
  const [appState, setAppState] = useContext(AppContext);

  //create a report list state for storing report items from server
  const [reportListA, setReportList] = useState({});

  //create a loading state
  const [loading, setLoading] = useState(true);

  //create drawer visibility state
  const [drawerVisibility, setDrawerVisibility] = useState(false);

  //create selected item state
  const [selectedItem, setSelectedItem] = useState({});

  //create a constant for collectionID to be monitored
  const collectionID = "system_reports";

  //create search box's input state
  const [searchInput, setSearchInput] = useState("");

  //create search query
  const [searchQuery, setSearchQuery] = useState(
    `filter=${JSON.stringify({
      status: {
        _eq: "published",
      },
    })}`
  );

  //create pagination state
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 4,
    total: 0,
  });

  //function for handling search box's input event
  const handleSearchEntry = (e) => {
    //set search input state to search box's current value
    setSearchInput(e.target.value);
  };

  //function for picking a page number from pagination
  const gotoPage = async (page) => {
    //show loading spinner
    setLoading(true);

    //clone pagination state and add the selected page number as current page
    const newPagination = { ...pagination, current: page };

    //update new pagination state
    setPagination(newPagination);

    //send request to server report items and wait for response
    await getReportList([appState, setAppState], setReportList, newPagination);

    //hide loading spinner
    setLoading(false);
  };

  //function for selecting a report item
  const selectItem = (reportData) => {
    //show report drawer
    setDrawerVisibility(true);

    //set selected item to state
    setSelectedItem(reportData);
  };

  //start up effect
  useEffect(() => {
    //start up function
    const startUp = async () => {
      //show loading spinner
      setLoading(true);

      //send request to server report items and wait for response
      await getReportList([appState, setAppState], setReportList, pagination);

      //hide loading spinner
      setLoading(false);
    };

    //run startup function
    startUp();
  }, []);

  //effect for search input state
  useEffect(() => {
    //define default query object
    const query = {
      _and: [
        {
          _or: [
            {
              title: {
                _contains: searchInput,
              },
            },
            {
              tagline: {
                _contains: searchInput,
              },
            },
            {
              content: {
                _contains: searchInput,
              },
            },
          ],
        },
        {
          status: {
            _eq: "published",
          },
        },
      ],
    };

    //define query string
    const queryString = searchInput.length
      ? `filter=${JSON.stringify(query)}`
      : `filter=${JSON.stringify({
          status: {
            _eq: "published",
          },
        })}`;

    //set query string to  search query state
    setSearchQuery(queryString);
  }, [searchInput]);

  //effect for search query
  useEffect(() => {
    //with 1.5 sec delay send query string state value to server
    const timeOut = setTimeout(async () => {
      //show loading spinner
      setLoading(true);

      //set pagination state to default (page: 1)
      setPagination({
        current: 1,
        pageSize: 4,
        total: 0,
      });

      //send query string state value to server and wait for response
      await getReportList(
        [appState, setAppState],
        setReportList,
        pagination,
        searchQuery
      );

      //hide loading spinner
      setLoading(false);
    }, 1500);

    //cleanup function for this effect
    //remove timeOut function
    return () => clearTimeout(timeOut);
  }, [searchQuery]);

  //effect function for report item changes from list
  useEffect(() => {
    //check if report list state includes meta property
    if (reportListA.meta) {
      //clone pagination state
      const newPagination = { ...pagination };
      //update clone's pagination total count to report list state's meta filter count
      newPagination.total = reportListA.meta.filter_count;

      //set the pagination clone value as pagination state value
      setPagination({ ...newPagination });
    }
  }, [reportListA]);

  //effect for listening database changes
  useEffect(() => {
    //create a new socket for listening
    const rt = socketIOClient(socket);

    //listen for "notify" event from socket
    rt.on("notify", (data) => {
      //check if the collection ID is the same with the received "notify" event
      //do nothing if collection is not system_reports
      //do something when socket is from system_reports
      if (data.collection === collectionID) {
        //clone state that contains report list
        const newCollection = { ...reportListA };

        //do something if event is an update event
        if (data.event === "items.update") {
          //loop through the cloned report list state
          newCollection.data.map((col, i) => {
            //update cloned report list item from socket
            if (col.id == data.item) {
              newCollection.data[i] = {
                ...newCollection.data[i],
                ...data.payload,
              };
            }
          });
        }

        //do something if event is a create event but if filtered do not update the clone
        else if (data.event === "items.create" && !searchInput.length) {
          //logic here where data is in data.payload : array of records created.
          //for pagination consistency increment filter count by 1
          newCollection.meta.filter_count++;

          //do something if in the first pagination page
          if (pagination.current === 1) {
            if (reportListA.data.length < pagination.pageSize) {
              newCollection.data.unshift(data.payload[0]);
            } else {
              newCollection.data.pop();
              newCollection.data.unshift(data.payload[0]);
            }
          }
          //if not in first pagination page re-fetch the current page to cascade list
          else {
            gotoPage(pagination.current);
          }
        }

        //do something if event is a delete event
        else if (data.event === "items.delete") {
          //for development purposes
          //will delete  item from view right away
          if (data.accountability.role === appState?.user?.role) {
            if (newCollection.data.length > 1) {
              newCollection.meta.filter_count--;
              gotoPage(pagination.current);
            } else {
              //disable item from list
              newCollection.data.map((col, i) => {
                if (col.id == data.item) {
                  newCollection.data[i].deleted = true;
                }
              });
            }
          }
        }
        //show loading spinner
        setLoading(true);

        //update report list state
        setReportList({ ...newCollection });

        //hide loading spinner
        setLoading(false);
      }
    });
    // clean up function if ever changes are made from search input and report list
    // this is due for search input state to be relevant
    return () => rt.disconnect();
  }, [reportListA, searchInput]);

  return (
    <>
      <Input
        onChange={handleSearchEntry}
        placeholder="Search [eg: title, tagline or content]"
      />
      <br />
      <br />
      <div>
        <Spin spinning={loading}>
          <Row gutter={12} style={{ margin: "10px" }}>
            {reportListA.data ? (
              <>
                {reportListA.data.map((r) => (
                  <Col
                    span={12}
                    sm={24}
                    xs={24}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={8}
                    style={{ marginBottom: "4vw" }}
                  >
                    <Card
                      title={
                        <Paragraph ellipsis={{ rows: 1, tooltip: r.title }}>
                          {r.title}
                        </Paragraph>
                      }
                      style={{ width: "100%", height: "100%" }}
                    >
                      <Paragraph
                        ellipsis={
                          screens.sm || screens.xs
                            ? false
                            : { rows: 1, tooltip: r.tagline }
                        }
                      >
                        {r.tagline}
                      </Paragraph>

                      <Paragraph
                        ellipsis={
                          screens.sm || screens.xs
                            ? false
                            : {
                                rows: 1,
                                tooltip: r.record_tag.join(", "),
                              }
                        }
                      >
                        {r.record_tag
                          ? r.record_tag.map((r) => <Tag>{r}</Tag>)
                          : null}
                      </Paragraph>
                      <div style={{ textAlign: "right" }}>
                        <Button
                          size="middle"
                          icon={<InfoCircleOutlined />}
                          onClick={() => selectItem(r)}
                        >
                          Info
                        </Button>
                      </div>
                    </Card>
                  </Col>
                ))}
              </>
            ) : null}
          </Row>
        </Spin>
      </div>
      <div style={{ textAlign: "right" }}>
        <Pagination {...pagination} onChange={gotoPage} />
      </div>
      <ReportDrawer
        visibilityState={[drawerVisibility, setDrawerVisibility]}
        selectedReportState={[selectedItem, setSelectedItem]}
      />
    </>
  );
}

export default ReportList;
