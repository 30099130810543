function conditioner(operator, a, b = "") {
  switch (operator) {
    case "_eq":
      return a === b;
      break;

    case "_neq":
      return a !== b;
      break;

    case "_lt":
      return a < b;
      break;

    case "_lte":
      return a <= b;
      break;

    case "_gt":
      return a > b;
      break;

    case "_gte":
      return a >= b;
      break;

    case "_contains":
    case "_in":
      if (Array.isArray(a)) {
        let value = false;
        a.map((item) => {
          if (!value) {
            try {
              value = b.includes(item);
            } catch (e) {
              value = false;
            }
          }
        });
        return value;
      } else {
        let value = false;
        try {
          value = b.includes(a);
        } catch (e) {
          value = false;
        }
        return value;
      }

      break;

    case "_ncontains":
    case "_nin":
      if (Array.isArray(a)) {
        let value = false;
        a.map((item) => {
          if (!value) {
            try {
              value = !b.includes(item);
            } catch (e) {
              value = true;
            }
          }
        });
        return value;
      } else {
        let value = false;
        try {
          value = !b.includes(a);
        } catch (e) {
          value = true;
        }
        return value;
      }
      break;

    case "_null":
      if (a && !b) {
        return true;
      } else if (!a && b) {
        return true;
      } else {
        return false;
      }
      break;

    case "_nnull":
      if (a && b) {
        return true;
      } else if (!a && !b) {
        return true;
      } else {
        return false;
      }
      break;

    case "_starts_with":
      return b.indexOf(a) === 0;
      break;

    case "_nstarts_with":
      return b.indexOf(a) !== 0;
      break;

    case "_ends_with":
      return b.indexOf(a) === b.length - b.indexOf(a) + 1;
      break;

    case "_nends_with":
      return b.indexOf(a) === b.length - b.indexOf(a) + 1;
      break;
  }
}

function conditionsManager(
  formState,
  conditions,
  defaultVisibility = true,
  setVisibility = () => {},
  defaultRules = [],
  setRules = () => {},
  defaultOptions = {},
  setOptions = () => [],
  defaultAllowOther = true,
  setAllowOther = () => {},
  label
) {
  let dv = defaultVisibility;
  let dr = defaultRules;
  let dOpt = defaultOptions;
  let da = defaultAllowOther;

  conditions.map((condition) => {
    const formNamePath = Object.keys(condition.rule)[0];
    const operator = Object.keys(condition.rule[formNamePath])[0];
    const test = condition.rule[formNamePath][operator];

    if (conditioner(operator, test, formState[formNamePath])) {
      if (condition?.hidden) {
        dv = !condition.hidden;
      }

      if (condition?.required) {
        dr = [
          ...defaultRules,
          {
            required: true,
            message: `Please enter ${label}`,
          },
        ];
      }

      if (condition?.options) {
        try {
          dOpt = condition.options.choices.map((opt) => ({
            label: opt.text,
            value: opt.value,
          }));
          da = condition.options.allowOther;
        } catch (e) {}
      }
    }
  });

  setVisibility(dv);
  setRules(dr);
  setOptions(dOpt);
  setAllowOther(da);
}

export default conditionsManager;
