import server from "../../../../constants/server";
import { message } from "antd";

const uploadFile = async ([appState, setAppState], data, callbacks = []) => {
  var myHeaders = new Headers();
  var formData = new FormData();
  myHeaders.append("Authorization", `Bearer ${appState.token}`);
  myHeaders.append("Accept", "application/json");

  formData.append("title", "form_photo");
  formData.append("file", data);

  var raw = data;
  var method = "POST";

  var requestOptions = {
    method: method,
    headers: myHeaders,
    body: formData,
    mode: "cors",
    redirect: "follow",
  };

  try {
    let response = null;
    response = await fetch(`${server}/files`, requestOptions);

    const result = await response.json();

    if (result.errors) {
      if (
        result.errors[0].message === "Token expired." ||
        result.errors[0].message === "Invalid user credentials."
      ) {
        setAppState({});
        return message.warning("Session expired, login again to continue");
      }
      return message.warning(result.errors[0].message);
    }
    if (result.data) {
      return result.data;
    }
  } catch (e) {
    console.log(e);
    message.error(
      "You are disconnected to the server. Please check your internet connection"
    );
  }
};

export default uploadFile;
