//import antDesign message module
import { message } from "antd";

//import server details
import server from "../../../constants/server";

const login = async ({ email, password }, setAppState, setdns, errCB) => {
  //setting headers
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  //login form inputs as payload
  var raw = JSON.stringify({
    email: email,
    password: password,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    //sending http request and fetch response
    const response = await fetch(`${server}/auth/login`, requestOptions);

    //convert response to JSON as result
    const result = await response.json();

    //check if server sent errors
    if (result.errors) {
      errCB();
      //notify user about server given error
      return message.warning(result.errors[0].message);
    }

    //check if result has new token
    if (result.data.access_token) {
      //add token to AppState
      setAppState({
        token: result.data.access_token,
        refresh_token: result.data.refresh_token,
      });
    } else {
      //notify user if server gave unexpected response
      message.error("Server tamper detected, please contact admin");
    }
  } catch (e) {
    //notify user if there's an client side error
    console.log(e);
    message.warning(
      "Your internet service provider's DNS not working properly"
    );
    setdns();
  }
};

export default login;
