//import npm modules
import React, { useContext, useEffect } from "react";
import { Divider, Typography, Affix } from "antd";

//import custom react components
import AnnouncementList from "./lists/AnnouncementList";

//import react contexts
import AppContext from "../../contexts/AppContext";

//import custom functions
import getUserData from "./functions/getUserData";

//deconstruct antDesign modules
const { Title } = Typography;

function Home() {
  //set AppState from AppContext
  const [AppState, setAppState] = useContext(AppContext);

  //startup function
  useEffect(() => {
    const startUp = async () => {
      //get user data and set it to AppState
      await getUserData([AppState, setAppState]);
    };
    startUp();
  }, []);
  return (
    <div>
      <Title>Home</Title>
      <Typography>Welcome back {AppState?.user?.first_name}!</Typography>
      <Divider>Announcements</Divider>
      <AnnouncementList />
    </div>
  );
}

export default Home;
