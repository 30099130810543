import server from "../../../../constants/server";
import { message } from "antd";
import moment from "moment";
const getCollection = async (
  collectionID,
  [AppState, setAppState],
  setState,
  pagination,
  findQuery,
  sortQuery = ["-date_created"]
) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${AppState.token}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${server}/items/${collectionID}?meta=*&sort=sort,${sortQuery.join(
        ","
      )}&limit=${pagination.pageSize}&page=${pagination.current}${
        findQuery.length ? "&filter=" + findQuery : ""
      }`,
      requestOptions
    );
    const result = await response.json();

    if (result.errors) {
      if (
        result.errors[0].message === "Token expired." ||
        result.errors[0].message === "Invalid user credentials."
      ) {
        setAppState({});
        return message.warning("Session expired, login again to continue");
      }
      return message.warning(result.errors[0].message);
    }

    const convertRecords = (data) => {
      data.map((row) => {
        try {
          Object.keys(row).map((col) => {
            const test = col.split("_");
            if (test.includes("address") && row?.[col]?.province) {
              const arry = [];
              Object.keys(row[col]).map((a) => {
                arry.push(row[col][a]);
              });
              row[col] = arry;
            }

            else if (test.includes("address") && !row?.[col]?.length) {
              const arry = [];
              row[col] = arry;
            }

            else if (test.includes("date") && test[0] !== "is") {
              if (row?.[col]) {
                row[col] = moment(row[col]);
              }
            }

            else if (Array.isArray(row[col])) {
              convertRecords(row[col]);
            }
          });
        } catch (e) {
          console.log(e);
        }
      });
    };

    convertRecords(result.data);

    setState(result);
  } catch (e) {
    console.log(e);
    message.error(
      "You are disconnected to the server. Please check your internet connection"
    );
  }
};

export default getCollection;
