//import npm modules
import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { message, Table, Typography, Grid } from "antd";
import socketIOClient from "socket.io-client";
import RecordUiContext from "../../../../contexts/RecordUiContext";
//import custom functions
import getItems from "../functions/getItems";
import socket from "../../../../constants/socket";
import elasticsearch from "../../../../constants/elasticsearch";

//import custom react contexts
import AppContext from "../../../../contexts/AppContext";

//import css
import "./rowStyle.css";

//destructure antDesign module
const { Paragraph } = Typography;
const { useBreakpoint } = Grid;

function DataTable({
  displayTemplate = "",
  selectItem,
  schema,
  findQueryState = [],
  sortQueryState = [],
  paginationState = [],
}) {
  //list active screens
  const screens = useBreakpoint();

  const [sortQuery, setSortQuery] = sortQueryState;

  //destructure collection id from useParams hook, url parameter
  const { collectionID } = useParams();

  //create appState from imported AppContext
  const [appState, setAppState] = useContext(AppContext);

  //create column state for table columns
  const [columns, setColumns] = useState([]);

  //destructure findQuery state from findQueryState prop
  const [findQuery, setFindQuery] = findQueryState;

  //
  const [findView, setFindView] = useState(false);

  //create pagination position state
  const [paginationPosition, setPaginationPosition] = useState([
    "none",
    "bottomRight",
  ]);

  //create pagination state
  const [pagination, setPagination] = paginationState;

  //create loading state
  const { uiLoading } = useContext(RecordUiContext);
  const [loading, setLoading] = uiLoading;

  //create data list state for storing response data for data table consumption
  const [data, setData] = useState([]);

  //create meta data state for storing response meta data from sever
  const [meta, setMeta] = useState({});

  //create collectionItems state for storing records
  const [collectionItems, setCollectionItems] = useState({});

  //function for object to string convertion
  const objtoString = (obj) => {
    const v = [];
    Object.keys(obj).map((key) => {
      v.push(obj[key]);
    });
    return v.join(", ");
  };

  //function for handling pagination navigation
  const gotoPage = async (page, sortQ, pgNation) => {
    const sortQueryHere = sortQ ? sortQ : sortQuery;
    const localPgNation = pgNation ? pgNation : pagination;
    //show loading spinner
    setLoading(true);

    //clone current pagination state and set clone page prop to current page
    const newPagination = { ...localPgNation, current: page };

    //update pagination state using the clone
    setPagination(newPagination);

    //check if findQuery state has value
    //and search base on that value and paginate
    if (findQuery.length) {
      //send query to server and wait for response
      //setLoading(true);
      await getItems(
        collectionID,
        [appState, setAppState],
        setCollectionItems,
        newPagination,
        findQuery,
        sortQueryHere
      );
    }

    //check if findQuery state has no value
    //and paginate normally
    else {
      //setLoading(false);
      await getItems(
        collectionID,
        [appState, setAppState],
        setCollectionItems,
        newPagination,
        "",
        sortQueryHere
      );
    }

    //hide loading spinner
    setLoading(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const tableActivity = (DIspagination, filters, sorter) => {
    let sortQ = [];

    if (sorter.order === "descend") {
      sortQ = ["-" + sorter.columnKey];
    } else if (sorter.order === "ascend") {
      sortQ = [sorter.columnKey];
    } else {
      sortQ = ["-date_created"];
    }

    setSortQuery(sortQ);
    gotoPage(DIspagination.current, sortQ, DIspagination);
  };

  //effect for collection Item changes,
  //as collectionItem state chages destructure data and metadata
  //to their designated states
  useEffect(() => {
    //check if data prop is an array
    if (Array.isArray(collectionItems.data)) {
      //update data state normally
      setData(collectionItems.data);
    } else {
      //update data state and pass data as an array
      setData([collectionItems.data]);
    }
    //update meta data state
    setMeta(collectionItems.meta);
  }, [collectionItems]);

  //effect for startup
  useEffect(() => {
    //start up function
    const startup = async () => {
      //show loading spinner
      setLoading(true);

      //initialized pagination defaults
      const newPagination = {
        current: 1,
        pageSize: 4,
        total: 0,
      };

      //add defaults to pagination state
      setPagination(newPagination);

      //check if search query has length
      if (findQuery.length) {
        setFindView(true);
        //find records in based on query and add it to view
        await getItems(
          collectionID,
          [appState, setAppState],
          setCollectionItems,
          newPagination,
          findQuery,
          sortQuery
        );
      } else {
        //get records normally and add it to view
        setFindView(false);
        await getItems(
          collectionID,
          [appState, setAppState],
          setCollectionItems,
          newPagination,
          "",
          sortQuery
        );
      }

      //hide loading spinner
      setLoading(false);
    };

    //run start up function
    startup();
  }, [collectionID, findQuery]);

  //create an responsive table pagination

  useEffect(() => {
    if (!screens.lg) {
      setPaginationPosition(["none", "bottomLeft"]);
    } else {
      setPaginationPosition(["none", "bottomRight"]);
    }
  }, [screens]);

  //effect for meta data changes and it relation to pagination state
  useEffect(() => {
    //check meta data
    if (collectionItems.meta) {
      const newPagination = { ...pagination };
      newPagination.total = collectionItems.meta.filter_count;
      setPagination({ ...newPagination });
    }
  }, [collectionItems]);

  useEffect(() => {
    const rt = socketIOClient(socket);

    rt.on("notify", (data) => {
      if (
        data.collection === collectionID &&
        data.accountability.role === appState?.user?.role
      ) {
        gotoPage(pagination.current);
      }
    });

    return () => rt.disconnect();
  }, [pagination]);

  useEffect(() => {
    let temp = displayTemplate.replace(/\}\{/g, ";");
    temp = temp.replace(/\}/g, "");
    temp = temp.replace(/\{/g, "");
    const tempArr = temp.split(";");

    if (tempArr.length) {
      const cols = tempArr.map((t, i) => ({
        sorter: true,
        dataIndex: t,
        key: t,
        title:
          `${findView ? "🔍 " : ""}` +
          t
            .split("_")
            .join(" ")
            .replace(/\w\S*/g, function (txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }),
        render: (data, record) => {
          const value = Array.isArray(data)
            ? data.join(", ")
            : typeof data === "object" && data
            ? objtoString(data)
            : data;
          return (
            <Paragraph
              ellipsis={{ rows: 1, tooltip: value }}
              style={
                record?.deleted
                  ? {
                      textDecoration: "line-through",
                      color: "gray",
                      cursor: "not-allowed",
                    }
                  : { cursor: "pointer" }
              }
            >
              {value}
            </Paragraph>
          );
        },
      }));
      cols.unshift({
        dataIndex: "ctr",
        key: "ctr",
        title: "#",
        render: (data, record, i) => {
          if (pagination.current === 1) {
            return i + 1;
          } else {
            return i + 1 + pagination.pageSize * (pagination.current - 1);
          }
        },
      });
      setColumns(cols);
    }
  }, [displayTemplate, findView, pagination]);

  return (
    <div
      style={{
        maxWidth: "100%",
        overflow: "auto",
        backgroundColor: "white",
      }}
    >
      <Table
        style={{ padding: "0px 7px" }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              if (record.deleted) {
                return message.warning("Record deleted from PSWDB");
              }
              selectItem(record);
            },
          };
        }}
        rowClassName={() => {
          return `tbl_row`;
        }}
        columns={columns}
        dataSource={[...data]}
        loading={loading}
        onChange={tableActivity}
        pagination={{
          position: paginationPosition,
          ...pagination,
        }}
      />
    </div>
  );
}

export default DataTable;
