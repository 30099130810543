import server from "../../../../constants/server";
import { message } from "antd";
const deleteItem = async (
  collectionID,
  [AppState, setAppState],
  itemID,
  callbacks = []
) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${AppState.token}`);

  var requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${server}/items/${collectionID}/${itemID}`,
      requestOptions
    );
    try {
      const result = await response.json();
      if (result.errors) {
        if (
          result.errors[0].message === "Token expired." ||
          result.errors[0].message === "Invalid user credentials."
        ) {
          setAppState({});
          return message.warning("Session expired, login again to continue");
        }
        return message.warning(result.errors[0].message);
      }
      if (callbacks?.length) {
        callbacks.map((c) => c());
      }
    } catch (e) {
      message.warning("Deleted");
    }
  } catch (e) {
    console.log(e);
    message.error(
      "You are disconnected to the server. Please check your internet connection"
    );
  }
};

export default deleteItem;
