import React from "react";
import { Divider, Typography } from "antd";
import { useHistory } from "react-router-dom";

const { Title, Text } = Typography;
function Maintenance({
  title = "SWDB Maintenance Day!",
  tagline = "We are commited to make SWDB better.",
  timeline = "Please visit us again after a few hours. Thank you!",
  image = `${window.location.origin}/giphy.gif`,
  showRefresh = true,
}) {
  const history = useHistory();

  const backHome = () => {
    history.push("/");
  };
  return (
    <div style={{ paddingTop: "20vh", textAlign: "center" }}>
      <div>
        <img src={image} style={{ width: "17%" }} />
      </div>
      <Title>{title}</Title>
      <Divider>{tagline}</Divider>
      <p>
        <Text>{timeline}</Text>
      </p>
      <p>
        {showRefresh ? (
          <Text>
            Click <a onClick={backHome}>here</a> to try and check
          </Text>
        ) : null}
      </p>
    </div>
  );
}

export default Maintenance;
