//import npm modules
import React, { useContext } from "react";
import { Drawer, Typography, Grid } from "antd";
import moment from "moment";
import parse from "html-react-parser";

//import server details
import server from "../../../constants/server";

//import react contexts
import AppContext from "../../../contexts/AppContext";

//deconstruct antDesign module
const { Text } = Typography;
const { useBreakpoint } = Grid;

function TrainingDrawer({ visibilityState, data }) {
  //drawer visibility state
  const [visibility, setVisibility] = visibilityState;

  //set Appstate from AppContext
  const [appState, setAppState] = useContext(AppContext);

  //function for closing drawer
  const handleClose = () => {
    setVisibility(false);
  };

  const screens = useBreakpoint();

  return (
    <Drawer
      closable={true}
      visible={visibility}
      onClose={handleClose}
      title={data.title}
      width={screens.xs || screens.sm !== screens.lg ? "100vw" : "60vw"}
    >
      {/** Show creation date if available */}
      {data.date_created ? (
        <p>
          Posted on: {moment(data.date_created).format("MMM DD, YYYY, h:mm a")}
        </p>
      ) : null}

      {/** Show training picture banner if available */}

      {data.training_video && visibility ? (
        <div style={{ textAlign: "center" }}>
          <video
            style={{ width: "90%" }}
            src={`${server}/assets/${data.training_video}?access_token=${appState.token}`}
            controls
          />
        </div>
      ) : (
        <>
          {data.banner ? (
            <div style={{ textAlign: "center" }}>
              <img
                src={`${server}/assets/${data.banner}?access_token=${appState.token}`}
                style={{ width: "40%" }}
              />
            </div>
          ) : null}
        </>
      )}

      <br />
      <br />

      {/** Show training tagline if available */}
      <div style={{ textAlign: "center", fontStyle: "italic" }}>
        {data.tagline ? <Text mark>&nbsp;"{data.tagline}"&nbsp;</Text> : null}
      </div>

      <br />
      <br />

      {/** Show training content if available */}
      {data.content ? parse(data.content) : null}

      <br />
    </Drawer>
  );
}

export default TrainingDrawer;
