import React, { useState, useEffect, useContext } from "react";
import { Form, DatePicker } from "antd";
import FormContext from "../../../../contexts/FormContext";
import conditionsManager from "../functions/conditionsManager";

function DateInput({
  pk,
  name,
  label,
  field,
  rules,
  instance,
  formHook,
  findMode,
  rname,
}) {
  const [formState, setFormState] = useContext(FormContext);
  const [conditions, setCondition] = useState(field?.meta?.conditions);
  const [localRules, setLocalRules] = useState(rules);
  const [visibility, setVisibility] = useState(true);

  useEffect(() => {
    if (findMode) {
      setLocalRules([]);
      setVisibility(true);
    } else if (conditions) {
      conditionsManager(
        formState,
        conditions,
        true,
        setVisibility,
        rules,
        setLocalRules,
        undefined,
        undefined,
        undefined,
        undefined,
        label
      );
    }
  }, [formState]);

  return (
    <>
      {visibility ? (
        <Form.Item
          key={pk}
          name={name}
          label={
            <span>
              {label}
              <br />
              <small>{field.meta.note}</small>
            </span>
          }
          rules={localRules}
          isListField={instance ? true : false}
        >
          <DatePicker format="MMMM DD, YYYY" style={{ width: "100%" }} />
        </Form.Item>
      ) : null}
    </>
  );
}

export default DateInput;
