import React, { useState, useContext, useEffect } from "react";
import { Typography, Divider, Descriptions, Button } from "antd";
import AppContext from "../../contexts/AppContext";
import requestChangePassword from "./requestChangePassword";
const { Title } = Typography;

function Account() {
  const [appState, setAppState] = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const sendRequest = async () => {
    setLoading(true);
    await requestChangePassword([appState, setAppState]);
    setLoading(false);
  };

  return (
    <div>
      <Title>Account</Title>
      <Typography>This is what we know about you.</Typography>
      <Divider />
      <div style={{ padding: "1vw", backgroundColor: "white" }}>
        <Descriptions title="User Info" bordered>
          <Descriptions.Item label="Full name" span={3}>
            {appState.user.first_name} {appState.user.middle_name}{" "}
            {appState.user.last_name}
          </Descriptions.Item>
          <Descriptions.Item label="Email" span={3}>
            {appState.user.email}
          </Descriptions.Item>
          <Descriptions.Item label="Designation">
            {appState.user.location ? appState.user.location : "no record"}
          </Descriptions.Item>
          <Descriptions.Item label="Title">
            {appState.user.title ? appState.user.title : "no record"}
          </Descriptions.Item>
        </Descriptions>
        <Divider />
        <Title level={5}>Change Password</Title>
        <Button type="dashed" loading={loading} onClick={sendRequest}>
          Request email for password change
        </Button>
        <br />
        <br />
        <small>
          An email will be sent to {appState.user.email} containing a link for a
          password reset.
        </small>
        <br />
        <small>To update other details, please contact PSWDO - Isabela.</small>
        <br />
        <small style={{ color: "red" }}>
          Beware: double check that the email sender will be only from{" "}
          <strong>administrator@swdb.one</strong>
        </small>
      </div>
    </div>
  );
}

export default Account;
