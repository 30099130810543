//import npm modules
import React, { useEffect, useContext, useState } from "react";
import { Divider, Typography, Row, Col, Card, Button, Grid, Spin } from "antd";
import { BookOutlined, RightOutlined } from "@ant-design/icons";
import { Link, useRouteMatch } from "react-router-dom";

//import custom react context
import AppContext from "../../contexts/AppContext";

//import custom functions
import getCollection from "./functions/getCollection";

//destructure antDesign module
const { Title, Paragraph } = Typography;
const { useBreakpoint } = Grid;

function Records() {
  //destructure url from useRouteMatch hook
  let { path, url } = useRouteMatch();

  //create App State from imported app context
  const [AppState, setAppState] = useContext(AppContext);

  //create collectionList state for storing list of collections received from db
  const [collectionList, setCollectionList] = useState([]);

  //create loading state for spinner visibility
  const [loading, setLoading] = useState(false);

  //create name normalization function
  const nameToNormal = (name) => {
    //return name from 'db_name' to 'Db Name'
    return name
      .split("_")
      .join(" ")
      .replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
  };

  // create a start up effect
  useEffect(() => {
    //create a start up function to get intial collection from db
    const startUp = async () => {
      //show loading spinner
      setLoading(true);

      //get collection from db
      await getCollection([AppState, setAppState], setCollectionList);

      //hide loading spinner
      setLoading(false);
    };

    //excecute start up function
    startUp();
  }, []);

  const screens = useBreakpoint();

  return (
    <div>
      <Title>Records</Title>
      <Typography>Your entries are listed here</Typography>
      <Divider />
      <div style={{ padding: "10px" }}>
        {loading ? <Spin spinning={true}>Loading...</Spin> : null}
        <Row gutter={8}>
          {collectionList.map((c) => (
            <Col
              span={8}
              key={c.collection}
              sm={24}
              xs={24}
              md={24}
              lg={12}
              xl={12}
              xxl={8}
              style={{ marginBottom: "4vw" }}
            >
              <Card
                title={
                  <Paragraph
                    ellipsis={
                      screens.sm || screens.xs
                        ? false
                        : {
                            rows: 1,
                            tooltip: nameToNormal(c.collection),
                          }
                    }
                  >
                    <BookOutlined style={{ marginRight: "7px" }} />
                    {nameToNormal(c.collection)}
                  </Paragraph>
                }
                bordered={false}
              >
                <Paragraph
                  ellipsis={
                    screens.sm || screens.xs
                      ? false
                      : { rows: 1, tooltip: c.meta.note }
                  }
                >
                  {c.meta.note}
                </Paragraph>
                <div style={{ textAlign: "right" }}>
                  <Link to={`${url}/${c.collection}`}>
                    <Button>
                      Go <RightOutlined />
                    </Button>
                  </Link>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}

export default Records;
