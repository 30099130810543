import React, { useState, useContext, useEffect } from "react";
import { Form, Cascader, Grid, Select } from "antd";
import FormContext from "../../../../contexts/FormContext";
import conditionsManager from "../functions/conditionsManager";
import getAddresses from "../functions/getAddresses";
import AppContext from "../../../../contexts/AppContext";
import RecordUiContext from "../../../../contexts/RecordUiContext";
const { useBreakpoint } = Grid;

function AddressCascader({
  pk,
  name,
  label,
  field,
  rules,
  instance,
  formHook,
  findMode,
  rname,
}) {
  const [formState, setFormState] = useContext(FormContext);
  const { recordDrawer } = useContext(RecordUiContext);
  const [drawerVisibility, setDrawerVisibility] = recordDrawer;
  const [AppState, setAppState] = useContext(AppContext);
  const [conditions, setCondition] = useState(field?.meta?.conditions);
  const [localRules, setLocalRules] = useState(rules);
  const [visibility, setVisibility] = useState(true);
  const [casecaderData, setCascaderData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (findMode) {
      setLocalRules([]);
      setVisibility(true);
    } else if (conditions) {
      conditionsManager(
        formState,
        conditions,
        true,
        setVisibility,
        rules,
        setLocalRules,
        mobileOptions,
        setMobileOptions,
        undefined,
        undefined,
        label
      );
    }
  }, [formState]);

  const [provinceList, setProvinceList] = useState([]);

  const [mobileOptions, setMobileOptions] = useState([]);

  const [currentValue, setCurrentValue] = useState([]);

  const screens = useBreakpoint();

  const displayRender = (label = [], selectedOptions) => {
    if (Array.isArray(label)) {
      return label.reverse().join(", ");
    } else {
      const newArr = Object.keys(label).map((key) => ({
        [key]: label[key],
      }));
      return newArr.reverse().join(", ");
    }
  };

  useEffect(() => {
    if (!casecaderData.length) {
      setMobileOptions(provinceList);
    }
  }, [findMode, screens, currentValue, casecaderData, drawerVisibility]);

  useEffect(() => {
    if (!drawerVisibility) {
      setCurrentValue([]);
    }
  }, [drawerVisibility]);

  useEffect(() => {
    if (instance?.isListField) {
      const fieldValue = formHook.getFieldValue(rname);
      if (fieldValue?.[name[0]]?.[name[1]]) {
        setCurrentValue([...fieldValue?.[name[0]]?.[name[1]]]);
      }
    } else {
      const v = formHook.getFieldValue(name);
      if (v?.length) {
        setCurrentValue([...formHook.getFieldValue(name)]);
      }
    }
  }, [formState]);

  useEffect(() => {
    (async function () {
      setLoading(true);
      await getAddresses([AppState, setAppState], setCascaderData, (data) => {
        setProvinceList(
          data.map((region) => ({
            value: region.value,
            label: region.label,
          }))
        );
      });
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (currentValue.length === 0) {
      setMobileOptions(provinceList);
    } else if (currentValue.length === 1) {
      const province = casecaderData
        .map((region) => {
          if (currentValue[0] === region.value) {
            return region.children.map((province) => {
              return {
                label: province.label,
                value: province.value,
              };
            });
          }
        })
        .flat(1)
        .filter((e) => e != null);
      setMobileOptions(province);
    } else if (currentValue.length === 2) {
      const municipality = casecaderData
        .map((region) => {
          if (currentValue[0] === region.value) {
            return region.children.map((province) => {
              if (currentValue[1] === province.value) {
                return province.children.map((municipality) => ({
                  label: municipality.label,
                  value: municipality.value,
                }));
              }
            });
          }
        })
        .flat(2)
        .filter((e) => e != null);
      setMobileOptions(municipality);
    } else if (currentValue.length === 3) {
      const barangay = casecaderData
        .map((region) => {
          if (currentValue[0] === region.value) {
            return region.children.map((province) => {
              if (currentValue[1] === province.value) {
                return province.children.map((municipality) => {
                  if (currentValue[2] === municipality.value) {
                    return municipality.children.map((barangay) => ({
                      label: barangay.label,
                      value: barangay.value,
                    }));
                  }
                });
              }
            });
          }
        })
        .flat(3)
        .filter((e) => e != null);
      setMobileOptions(barangay);
    } else if (currentValue.length === 4) {
      setMobileOptions([]);
    }
  }, [currentValue, casecaderData, provinceList]);

  const handleChange = (v) => {
    if (currentValue.length > 4) {
      setCurrentValue([]);
    }
    setCurrentValue(v);
  };

  const handleDeselect = () => {
    if (instance?.isListField) {
      const fieldValue = formHook.getFieldValue(rname);
      Object.assign(fieldValue[name[0]], {
        [name[1]]: [],
      });
    } else {
      formHook.setFieldsValue({ [name]: [] });
    }

    setCurrentValue([]);
  };

  function filter(inputValue, path) {
    return path.some(
      (option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );
  }

  return (
    <>
      {visibility ? (
        !loading ? (
          <>
            {!screens.lg || !screens.md ? (
              <Form.Item
                key={pk}
                name={name}
                label={
                  <span>
                    {label}
                    <br />
                    <small>{field.meta.note}</small>
                  </span>
                }
                rules={localRules}
                isListField={instance ? true : false}
              >
                <Select
                  options={mobileOptions}
                  onChange={handleChange}
                  onDeselect={handleDeselect}
                  showSearch
                  mode="tags"
                />
              </Form.Item>
            ) : (
              <Form.Item
                key={pk}
                name={name}
                label={
                  <span>
                    {label}
                    <br />
                    <small>{field.meta.note}</small>
                  </span>
                }
                rules={localRules}
                isListField={instance ? true : false}
              >
                <Cascader
                  options={casecaderData}
                  showSearch={{ filter }}
                  displayRender={displayRender}
                />
              </Form.Item>
            )}
          </>
        ) : (
          <Form.Item
            key={pk}
            name={name}
            label={
              <span>
                <span style={{ color: "red" }}>{label} Loading... </span>
              </span>
            }
            rules={localRules}
            isListField={instance ? true : false}
          >
            <input readOnly placeholder="loading addresses..." />
          </Form.Item>
        )
      ) : null}
    </>
  );
}

export default AddressCascader;
