//import npm modules
import React, { useState, useEffect, useContext } from "react";
import { List, Pagination, Avatar, Typography, Input } from "antd";
import moment from "moment";
import socketIOClient from "socket.io-client";

//import sockets
import socket from "../../../constants/socket";

//import custom react components
import TrainingDrawer from "../drawers/TrainingDrawer";

//import react contexts
import AppContext from "../../../contexts/AppContext";

//import custom functions
import getTrainings from "../functions/getTrainings";

//import server detials
import server from "../../../constants/server";

//deconstruct antDesign module
const { Title, Paragraph } = Typography;

function TrainingList() {
  //create AppState from AppContext
  const [appState, setAppState] = useContext(AppContext);

  //create pagination state and defaults
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 3,
    total: 0,
  });

  //create training state for item list and metaData
  const [trainingItems, setTrainingItems] = useState({});

  //create data state for training list item array
  const [data, setData] = useState([]);

  //create meta state  for training list meta data array
  const [meta, setMeta] = useState([]);

  //create list loading state
  const [loading, setLoading] = useState(false);

  //create training drawer visibility state
  const [drawerVisibility, setDrawerVisibility] = useState(false);

  //create selected training state
  const [selectedItem, setSelectedItem] = useState({});

  //create search input state
  const [searchInput, setSearchInput] = useState("");

  //create search query state and defaults
  //show only published trainings
  const [searchQuery, setSearchQuery] = useState(
    `filter=${JSON.stringify({
      status: {
        _eq: "published",
      },
    })}`
  );

  //function for every keystroke in search box
  const handleSearchEntry = (e) => {
    //setting search input state from search box
    setSearchInput(e.target.value);
  };

  //function for pagination selecting a page number
  const gotoPage = async (page) => {
    //show loading spinner
    setLoading(true);

    //create a new pagination state
    //setting pagination state's current page to selected page number
    const newPagination = { ...pagination, current: page };

    //setting new pagination state
    setPagination({ ...newPagination });

    //getting trainings from selected page number
    await getTrainings(
      [appState, setAppState],
      setTrainingItems,
      newPagination,
      searchQuery
    );

    //hide loading spinner
    setLoading(false);
  };

  //function for selecting an training
  const openDrawer = (data) => {
    //set clicked training data as selected item
    setSelectedItem(data);

    //show training drawer with detials
    setDrawerVisibility(true);
  };

  //start up function
  useEffect(() => {
    const starUp = async () => {
      //show loading spinner
      setLoading(true);

      //get newest list of trainings
      await getTrainings(
        [appState, setAppState],
        setTrainingItems,
        pagination,
        searchQuery
      );

      //hide loading spinner
      setLoading(false);
    };
    starUp();
  }, []);

  //updating data and meta state when trainingItem state changes
  useEffect(() => {
    setData(trainingItems.data);
    setMeta(trainingItems.meta);
  }, [trainingItems]);

  //setting filter count as total count for pagination page number consistency
  useEffect(() => {
    if (trainingItems?.meta?.filter_count) {
      const newPagination = { ...pagination };
      newPagination.total = trainingItems?.meta?.filter_count;
      setPagination({ ...newPagination });
    }
  }, [trainingItems]);

  //generate query string every time theres changes in searchbox
  useEffect(() => {
    //query variable based on directus terms
    const query = {
      _and: [
        {
          _or: [
            {
              title: {
                _contains: searchInput,
              },
            },
            {
              tagline: {
                _contains: searchInput,
              },
            },
            {
              content: {
                _contains: searchInput,
              },
            },
          ],
        },
        {
          status: {
            _eq: "published",
          },
        },
      ],
    };

    //http query string for directus server
    const queryString = searchInput.length
      ? `filter=${JSON.stringify(query)}`
      : `filter=${JSON.stringify({
          status: {
            _eq: "published",
          },
        })}`;

    //set query string to search query state
    setSearchQuery(queryString);
  }, [searchInput]);

  //send search query every time search query state changes
  //with delay of 1.5 seconds
  useEffect(() => {
    //time out function that sends search query with 1.5 seconds delay
    const timeOut = setTimeout(async () => {
      //show loading spinner
      setLoading(true);

      //set pagination to first page with 3 results each page
      setPagination({
        current: 1,
        pageSize: 3,
        total: 0,
      });

      //send search query and add to training items state
      await getTrainings(
        [appState, setAppState],
        setTrainingItems,
        pagination,
        searchQuery
      );

      //hide loading spinner
      setLoading(false);
    }, 1500);

    //clean up function for time out
    return () => clearTimeout(timeOut);
  }, [searchQuery]);

  //listen for sockets
  useEffect(() => {
    //define socket client instance as rt
    const rt = socketIOClient(socket);

    //listen for "notify" event from server
    rt.on("notify", async (data) => {
      //check if system_training collection got an update from server
      if (data.collection === "system_training") {
        await getTrainings(
          [appState, setAppState],
          setTrainingItems,
          pagination,
          searchQuery
        );
      }
    });

    //clean up function whenever component is destroyed
    return () => rt.disconnect();
  }, [trainingItems, searchInput]);

  return (
    <>
      {/** search box*/}
      <Input
        onChange={handleSearchEntry}
        placeholder="Search [eg: title, tagline or content]"
      />
      <br />
      <br />
      <div>
        {/** training list*/}
        <List
          loading={loading}
          dataSource={data}
          renderItem={(item) => (
            <List.Item
              itemLayout="vertical"
              size="large"
              onClick={() => openDrawer(item)}
              style={{ cursor: "pointer", padding: "7px" }}
            >
              {/** list item*/}
              <List.Item.Meta
                avatar={
                  <Avatar
                    shape="square"
                    size={100}
                    src={`${server}/assets/${item.banner}?access_token=${appState.token}`}
                  />
                }
                title={
                  <a>
                    <Title level={3}>
                      <Paragraph
                        ellipsis={{ rows: 1, tooltip: item.title }}
                        style={{ margin: "0px" }}
                      >
                        {item.title}
                      </Paragraph>
                    </Title>
                  </a>
                }
                description={
                  <span>
                    {moment(item.date_created).format("MMM DD, YYYY, h:mm a")}
                    <br />
                    <Paragraph ellipsis={{ rows: 1, tooltip: item.tagline }}>
                      {item.tagline}
                    </Paragraph>
                  </span>
                }
              />
            </List.Item>
          )}
        />
      </div>
      <div style={{ textAlign: "right", marginBottom: "10px" }}>
        {/** Pagination UI*/}
        <Pagination
          current={pagination.current}
          pageSize={pagination.pageSize}
          total={pagination.total}
          onChange={gotoPage}
        />
      </div>
      {/** Announcement drawer for viewing content*/}
      <TrainingDrawer
        visibilityState={[drawerVisibility, setDrawerVisibility]}
        data={selectedItem}
      />
    </>
  );
}

export default TrainingList;
