//import antDesign message module
import { message } from "antd";

//import server details
import server from "../../../constants/server";

const getReportList = async (
  [AppState, setAppState],
  setState,
  pagination,
  searchQuery
) => {
  //setting http headers
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${AppState.token}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    //send http request and wait for response
    const response = await fetch(
      `${server}/items/system_reports?meta=*&sort=sort,-date_created&limit=${pagination.pageSize}&page=${pagination.current}&${searchQuery}`,
      requestOptions
    );

    // convert response to JSON object
    const result = await response.json();

    //check if server responed with errors
    if (result.errors) {
      //if server detected and expired token
      if (
        result.errors[0].message === "Token expired." ||
        result.errors[0].message === "Invalid user credentials."
      ) {
        //clear AppState values
        setAppState({});

        //notify users to re-login due to expired token
        return message.warning("Session expired, login again to continue");
      }

      // notify users about server given errors
      return message.warning(result.errors[0].message);
    }

    //add response to state
    setState(result);
  } catch (e) {
    // notify user about client side errors
    console.log(e);
    message.error(
      "You are disconnected to the server. Please check your internet connection"
    );
  }
};

export default getReportList;
