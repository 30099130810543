//import npm modules
import React, { useContext, useEffect } from "react";
import { Divider, Typography, Affix } from "antd";

//import custom react components
import TrainingList from "./lists/TrainingList";

//import react contexts
import AppContext from "../../contexts/AppContext";

//import custom functions
import getUserData from "./functions/getUserData";

//deconstruct antDesign modules
const { Title } = Typography;

function Training() {
  //set AppState from AppContext
  const [AppState, setAppState] = useContext(AppContext);

  //startup function
  useEffect(() => {
    const startUp = async () => {
      //get user data and set it to AppState
      await getUserData([AppState, setAppState]);
    };
    startUp();
  }, []);
  return (
    <div>
      <Title>Training</Title>
      <Typography>
        Learn to use SWDB through these video tutorials. Discover simple
        techniques to perform powerful tasks.
      </Typography>
      <Divider>Courses</Divider>
      <TrainingList />
    </div>
  );
}

export default Training;
