import React from "react";
import { Modal, Button, Input, Form } from "antd";
function ModalForMultiSelect({
  VisibilityState = [false, () => {}],
  AddFunction = () => {},
  label = "",
  name = "",
  title = `Others (Specify)`,
}) {
  const [visibility, setVisibility] = VisibilityState;
  const [form] = Form.useForm();
  const close = () => {
    form.resetFields();
    setVisibility(false);
  };

  const finish = (d) => {
    AddFunction(d[name + "modal"]);
    form.resetFields();
    close();
  };
  return (
    <Modal title={title} visible={visibility} closable={false} footer={[]}>
      <Form onFinish={finish} form={form} layout="vertical">
        <Form.Item
          label={label}
          name={name + "modal"}
          rules={[
            {
              required: true,
              message: "Either enter new value or click cancel",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item style={{ textAlign: "right" }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          {"  "}
          <Button onClick={close}>Cancel</Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ModalForMultiSelect;
