import React, { useState, useEffect } from "react";
import { useLocation, useHistory, Redirect } from "react-router-dom";
import { Typography, Form, Input, Button, Divider } from "antd";
import changePassword from "./changepassword";

const { Title } = Typography;

function ResetPassword() {
  const path = useLocation().pathname;
  const URLparams = new URLSearchParams(useLocation().search);
  const [token, setToken] = useState(URLparams.get("token"));
  const [newPassword, setNewPassword] = useState(NaN);
  const [confirmPassword, setConfirmPassword] = useState(NaN);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();

  const formChange = (d) => {
    const [item] = d;
    if (item.name[0] === "password") {
      if (item.value.length) {
        setNewPassword(item.value);
      } else {
        setNewPassword(NaN);
      }
    } else if (item.name[0] === "confirm_password") {
      if (item.value.length) {
        setConfirmPassword(item.value);
      } else {
        setConfirmPassword(NaN);
      }
    }
  };

  const submit = async (d) => {
    let data = { token: token, password: d.password };
    setLoading(true);
    changePassword(data, path, [
      () => {
        history.push("/");
      },
    ]);
    setLoading(false);
  };

  return (
    <>
      {!token ? (
        <Redirect to="/" />
      ) : (
        <div style={{ padding: "5vw" }}>
          <Title>
            SWDB {path?.includes("invite") ? "Invite" : "Password Reset"}
          </Title>
          <Typography>
            Please enter your new password then click on submit
          </Typography>
          <Divider />
          <Form layout="vertical" onFieldsChange={formChange} onFinish={submit}>
            <Form.Item
              name="password"
              label="New Password"
              rules={[
                { required: true, message: "Please enter your new password!" },
                { min: 8, message: "Please enter at least 8 characters" },
              ]}
            >
              <Input type="password" />
            </Form.Item>
            <Form.Item
              name="confirm_password"
              label="Confirm New Password"
              rules={[
                {
                  required: true,
                  message: "Please re-enter your new password!",
                },
                { min: 8, message: "Please enter at least 8 characters" },
              ]}
            >
              <Input type="password" />
            </Form.Item>
            {newPassword === confirmPassword ? (
              <Form.Item>
                <Button htmlType="submit" type="primary" loading={loading}>
                  Submit
                </Button>
              </Form.Item>
            ) : newPassword.length || confirmPassword.length ? (
              <Button disabled>Passwords do not match</Button>
            ) : (
              <Button type="ghost" disabled>
                Please enter your new password first
              </Button>
            )}
          </Form>
        </div>
      )}
    </>
  );
}

export default ResetPassword;
